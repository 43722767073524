import React from "react"
import PropTypes from "prop-types"

import { getDateAsString} from "../utils/Helpers"

export const SubmissionDetailsComponent = ({submission}) => {
  return (
    <>
      <p>
        <strong>Tracking ID: </strong>{submission.trackingId}<br />
        <strong>Submission Date: </strong>{getDateAsString(submission.stampDate)}
      </p>
    </>
  )
}

SubmissionDetailsComponent.propTypes = {
  submission: PropTypes.object.isRequired
}
