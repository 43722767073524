import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { ROUTE_PATHS } from "../utils/routes"
import { OfferApi, AuthApi, LookupApi } from "../utils/api"
import { handleSubmissionError } from "../utils/FormUtils"
import { setDnsSetting } from "../utils/Helpers"
import AnalyticsService from "../utils/AnalyticsService"
import { AppContext } from "../components/context/AppContext"
import { GetStartedExlusiveCodeComponent, ExclusiveCodeComponent, LoadingSpinner } from "../components"
import { ErrorMessage } from "../components/styled"

export const ExlusiveCode = ({ location, history, match }) => {
  const { state, dispatch } = useContext(AppContext)
  const [isRecaptchaVisible, setIsRecaptchaVisible] = useState(false)
  const [isLoadingCaptcha, setIsLoadingCaptcha] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [isNetworkOutage, setIsNetworkOutage] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const exclusiveCode = match.params.exclusiveCode || ""
  
  const setDnsCookie = (value) => {
    const date = new Date()
    const expiration = new Date(date.getTime() + 31536000000)
    const cookie = (value.toLowerCase() === "donotsell" ? "dnt=1" : "dnt=0") + ";domain=" + window.location.domain + ";expires=" + expiration.toGMTString()
    document.cookie = cookie
    }

  state.showSubmitButton = true

  useEffect(() => {
     AnalyticsService.sendUniquePageview(ROUTE_PATHS.EXCLUSIVECODE);
   
    const getRecaptchaVisible = async () => {
      setIsLoadingCaptcha(true)
      try {
        const maintenance = await OfferApi.getAssetsByKeywordHookup("MaintenanceFlag")
        if (maintenance.notes.toLowerCase() === "true") {
          setIsNetworkOutage(true)
          return
        }

        const result = await OfferApi.getClientReCaptchaVisible()
        setIsRecaptchaVisible(result)
        setIsLoadingCaptcha(false)

      } catch (error) {
        setIsNetworkOutage(true)
        setIsLoadingCaptcha(false)
      }
    }
    getRecaptchaVisible()   
  }, [])

  useEffect(() => {
    const login = async () => {
      setIsLoggingIn(true)
      let isExistingCustomer = false
      let response 
      try {
     

        if (sessionStorage.getItem("pvtkey") === null) {
          var rs = require("jsrsasign");
          var keypair = rs.KEYUTIL.generateKeypair("RSA", 1024);
          sessionStorage.setItem("pubkey", rs.KEYUTIL.getPEM(keypair.pubKeyObj));
          sessionStorage.setItem(
            "pvtkey",
            rs.KEYUTIL.getPEM(keypair.prvKeyObj, "PKCS1PRV")
          );
        }
        
        const searchParams = new URLSearchParams(location.search)
        const code = searchParams.get("code")
        const sessionNumber = searchParams.get("session_num");
        const pubkey = sessionStorage.getItem("pubkey");
        const stateValue = searchParams.get("state");
        
        if (!code || state.lookupValues.isLoggedIn) {
          setIsLoggingIn(false)      
          isExistingCustomer = false    
          return
        }
        else if (state.user.userToken) {
            setIsLoggingIn(false)
            return
          }
        else {
          isExistingCustomer = true
        }

        if (isExistingCustomer) {
          response = await AuthApi.loginWithTmoId(code, state.lookupValues.phoneNumber, sessionNumber,pubkey, stateValue)

          sessionStorage.setItem("primaryPhoneNumber", response.primaryPhoneNumber)          
          const dnsSetting = await AuthApi.getGlobalDnsSettingV2(response.tMobileId)
          setDnsCookie(dnsSetting.localDoNotSellSetting)
          setDnsSetting(dnsSetting, response.tMobileId)
          
          dispatch({ type: "SETUSER", payload: response })
        }
        setIsLoggingIn(false)
      } catch (error) {        
        if (error.message === "Invalid Role" ) {
          setErrorMessage("We're sorry, you must be a primary account holder to apply this promotion. For more information, please contact T-Mobile Customer Care at 1.800.937.8997 for assistance.")          
        }
        else if (error.message === "PhoneNumbers Not Found" ) {
          setErrorMessage("You must have an active T-Mobile account to apply for this offer.")          
        }
        else {
          setErrorMessage("We were unable to log you in using your T-Mobile ID. Please login again.")
        }
        setIsLoggingIn(false)
      }
    }

    login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const onNewSubmissionSubmit = async (values, { setSubmitting, setStatus }) => {
    let lookupValues = { ...values, isTMobileCustomer: false }
    dispatch({ type: "SETLOOKUPVALUES", payload: lookupValues })
    try {        
      const searchParams = new URLSearchParams(location.search)
      const code = searchParams.get("code")
      if (!code) {
        setIsLoggingIn(false)
        lookupValues.isTMobileCustomer = false        
      }
      else {        
        lookupValues.isTMobileCustomer = true
      }
      
      let hookupRequest = {
        Recaptcha:lookupValues.recaptcha,
        code: lookupValues.promoCode
      }

      //Validate the promo and return campaigm, assets, offer and TnC If its valid in DB
      let campaign = await LookupApi.getCampaign(hookupRequest)     

      if(campaign){
        dispatch({type: "SETCAMPAIGN", payload: campaign})   
        AnalyticsService.sendGa4Event( {event: "signin",category:"Home",action:"Upload_Site",label:"Sign_In"})   
        history.push(ROUTE_PATHS.PROMOTION)
      }                
    } catch (error) {
      if (error.message === "Hookup code not found") {
        handleSubmissionError(setSubmitting, setStatus, "There were no promotions that matched your search criteria.")
        return
      }
      if (error.message === "CODE_EXPIRED") {
        handleSubmissionError(setSubmitting, setStatus, "This code has already been used. If you need help, please reach out to the person you received the code from or contact our Team of Experts at 1.800.937.8997.")
        return
      }
      if (error.message === "OFFER_EXPIRED") {
        handleSubmissionError(setSubmitting, setStatus, "We're sorry, this offer is no longer active. Please try again with a valid exclusive code.")
        return
      }
      handleSubmissionError(setSubmitting, setStatus)
    }
  }

  return (
    <>
      {isNetworkOutage
        ?
        <div className="text-center container">
          <h1 className="magenta">Temporarily Unavailable</h1>
          <p>This site is currently under going scheduled maintenance. We&apos;ll be back soon.</p>
          <p>Thank you for your patience.</p>
        </div>
        :
        <>
        {!isLoadingCaptcha && <GetStartedExlusiveCodeComponent />}
          {!isLoggingIn && errorMessage && <div className="container">
            <div className="row no-gutters2">
              <div className="mx-auto col-md-12">
                <ErrorMessage className="text-center">{errorMessage}</ErrorMessage>
              </div>
            </div>
          </div>}
          {(isLoadingCaptcha || isLoggingIn) && <div className="container text-center"><div className="mx-auto col-md-12"><LoadingSpinner /></div></div>}
          {(!isLoadingCaptcha && !isLoggingIn) &&
          <>
            <ExclusiveCodeComponent
              onSubmit={onNewSubmissionSubmit}
              isReCaptchaVisible={isRecaptchaVisible}
              submission={state.selectedSubmission}
              exclusiveCode={exclusiveCode} />

          </>
          }
        </>
      }
      </>
  )
}

ExlusiveCode.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
