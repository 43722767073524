import {AuthApi} from "../utils/api"

export const getDateAsString = (date) => new Date(date).toLocaleDateString()

export const getSubmissionStatusAsString = (submission) => {
  if (!submission.submitId) {   // this is an isubmission
    if (submission.statusId !== 0 || submission.statusId !== 3) {
      return "Pending"
    }

    return "Partial"
  }

  if (submission.offerErrors.length > 0) {
    return "Denied"
  }

  if (submission.debitCard && submission.debitCard.issueStatus === 2) {
    return "Sent"
  }

  if (submission.valid === true && submission.statusId === 1) {
    return "Approved"
  }

  return "Received"
}

export const getVirtualPayRedemptionLink = ({virtualPayClaimCode}) => {
  return virtualPayClaimCode.includes("https://login.wirecard.com/payment/")
    ? virtualPayClaimCode
    : `${process.env.REACT_APP_VIRTUAL_PAY_URL}${virtualPayClaimCode}`
}

export const parseClientFaq = (faq) => {
  const faqHeaders = [...new Set(faq.map(question => question.headerText))]
  const parsedFaq = []

  faqHeaders.forEach((header) => {
    const questionArray = faq
      .filter((question => question.headerText === header))
      .sort((a, b) => a.itemDisplayOrder - b.itemDisplayOrder)

    const question = {header: header, questions: questionArray }
    parsedFaq.push(question)
  })

  return parsedFaq
    .sort((q1, q2) => q1.questions[0].headerDisplayOrder - q2.questions[0].headerDisplayOrder)
}

export const createMarkup = (text) => {
  return {__html: text}
}

export const resetDispatch = async (state, dispatch) => {
  dispatch({type: "SETISUBMISSION", payload: ""})
  const removeAll = state.files.filter((file) => file.name === "")
  dispatch({type: "SETFILES", payload: removeAll})
  dispatch({type: "SETCAMPAIGN", payload: ""})
  dispatch({type: "SETSELECTEDPHONE", payload: ""})
  dispatch({type: "SETCHOSEMAILIN", payload: false})
}

export const validateImei = (imei) => {
  const imeiArray = imei.split("")

  const validationArray = imeiArray.map((digit, index) => {
    let parsedDigit = parseInt(digit, 10)

    if (index % 2 !== 0) {
      parsedDigit = parsedDigit * 2
    }

    if (parsedDigit > 9) {
      parsedDigit = ((parsedDigit % 10) + (parsedDigit / parsedDigit))
    }

    return parsedDigit
  })

  const validationTotal = validationArray.reduce( (accumulator, currentValue) => accumulator + currentValue)

  return validationTotal % 10 === 0
}

export const getCampaignAsset = (assets, keyword) => {
  const asset = assets.find(asset => asset.keyword === keyword)

  return asset ? asset.notes : ""
}

export const isEmpty = (obj) => {
  if (obj == null) { return true }
  if (obj.length > 0) { return false }
  if (obj.length === 0) { return true }
  if (typeof obj !== "object") { return true }

  let hasOwnProperty = Object.prototype.hasOwnProperty

  for (let key in obj) {
    if (hasOwnProperty.call(obj, key)) { return false }
  }

  return true
}

export const getLineStatusFromLookup = (lookup) => {
  if (lookup.hasOwnProperty('status')) {
    switch (lookup.status) {
      case 1:
        return 'Suspended';

      case 2:
        return 'Active';

      case 3: 
        return 'Reserved';

      default:
        return 'Unknown';
    
    }
  } else {
    return lookup;
  }
}

export const setDnsSetting = async (dnsObject, tMobileId) => {
  if(navigator.globalPrivacyControl ){
    if(dnsObject.globalDoNotSellSetting === 'Sell'){
      await AuthApi.setGlobalDnsSetting(tMobileId, 1)
    }
    else if(dnsObject.globalDoNotSellSetting === 'DoNotSell' && dnsObject.globalDoNotSellSettingDate == null){
      await AuthApi.setGlobalDnsSetting(tMobileId, 1)
    }
  }
}
