import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {UncontrolledTooltip} from "reactstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons"


const reservedToolTipText = "Number of total exclusive code uses that have been entered and are in the 14-day port-in waiting period. Their submission is still pending."
const redeemedToolTipText = "Number of total exclusive code uses that have been entered and successfully ported-in. Their submission is complete"
const availableToolTipText = "Number of total exclusive code uses that are available to be entered at this time"

export const LookupResponseComponent = ({hookupCode, offerName}) => {
  const [availableInvites, setAvailableInvites] = useState()

  useEffect(() => {
    if (hookupCode !== undefined) {
      let available = hookupCode.maximumUsage - hookupCode.currentUses - hookupCode.redeemedUses

      if (available > 0) {
        setAvailableInvites(available)
      } else {
        setAvailableInvites(0)
      }
    }
  }, [hookupCode])

  return (
    <div>
      {hookupCode && offerName && <div>
        <h3>Your exclusive code can be used up to {hookupCode.maximumUsage} times</h3>
        <h4>Offer Name: {offerName}</h4>
        <p>Invites Reserved: {hookupCode.currentUses} <FontAwesomeIcon icon={faQuestionCircle} className="magenta" id="reservedToolTip"/></p>
        <UncontrolledTooltip placement="top" target="reservedToolTip">
          {reservedToolTipText}
        </UncontrolledTooltip>
        <p>Invites Redeemed: {hookupCode.redeemedUses} <FontAwesomeIcon icon={faQuestionCircle} className="magenta" id="redeemedToolTip"/></p>
        <UncontrolledTooltip placement="right" target="redeemedToolTip">
          {redeemedToolTipText}
        </UncontrolledTooltip>
        <p>Invites Available: {availableInvites} <FontAwesomeIcon icon={faQuestionCircle} className="magenta" id="availableToolTip"/></p>
        <UncontrolledTooltip placement="right"target="availableToolTip">
          {availableToolTipText}
        </UncontrolledTooltip>
      </div>}
    </div>
  )
}

LookupResponseComponent.propTypes = {
  hookupCode: PropTypes.object,
  offerName: PropTypes.string.isRequired
}
