import React from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import {Formik, Form, Field } from "formik"

import { TextInput } from "./TextInput"
import {Button, ErrorMessage} from "../styled"

const initialValues = {
  ban: ""
}

const validationSchema = Yup.object().shape({
  ban: Yup.string().length(9, "Please enter a nine digit BAN").required("Please enter a BAN")
})

export const BanLogInForm = (props) => {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.onSubmit}>
      {({isSubmitting, status}) => (
        <Form>
          <div><p className="small">Use this BAN login for testing in lower environments</p></div>
          <div className="row no-gutters2">
            <div className="form-group col-md-12">
              <Field name="ban" component={TextInput} type="text" placeholder="BAN" />
            </div>
          </div>
          <div className="mt-3 text-center text-md-left">
            <Button type="submit"className="btn" primary disabled={isSubmitting}>Submit</Button>
            {status && status.formError && <ErrorMessage>{status.formError}</ErrorMessage>}
          </div>
        </Form>)}
    </Formik>
  )
}

BanLogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
