import React, {useContext, useEffect} from "react"
import PropTypes from "prop-types"

import {SubmissionDetailsComponent} from "../components"
import {ThankYouSection, Button} from "../components/styled"
import {AppContext} from "../components/context/AppContext"

import {ROUTE_PATHS} from "../utils/routes"
import {ASSET_KEYWORDS} from "../utils/assetKeywords"
import AnalyticsService from "../utils/AnalyticsService"

export const Thanks = (props) => {
  const {state, dispatch} = useContext(AppContext)
  
  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.THANKS);
  });

  const startNewSubmission = () => {
    dispatch({type: "RESETSTATE", payload: state.user})
    props.history.push(ROUTE_PATHS.HOME)
  }

  const renderThankYouText = () => {
    const thankYouAsset = state.campaign.campaignAssets.find((x) => x.keyword === ASSET_KEYWORDS.THANKYOU_TEXT)
    if (!(thankYouAsset && thankYouAsset.notes)) {
      return
    }

    return <div dangerouslySetInnerHTML={{__html: thankYouAsset.notes}}></div>
  }

  const renderThankYou2Text = () => {
    const thankYouAsset = state.campaign.campaignAssets.find((x) => x.keyword === ASSET_KEYWORDS.THANKYOU2_TEXT)
    if (!(thankYouAsset && thankYouAsset.notes)) {
      return
    }

    return <div dangerouslySetInnerHTML={{__html: thankYouAsset.notes}}></div>
  }

  return (
    <ThankYouSection>
      <div className="container-fluid hero2">
        <div className="row no-gutters2">
          <div className="container mx-auto">
            <h1 className="font-teleNeoWeb">Thanks.</h1>
            <div class="row">
              <div class="col-md-6">
                <h3>We&apos;ve received your submission. Here are the details.<br /></h3>
                {renderThankYouText()}
                {renderThankYou2Text()}
                <p>Use your tracking ID below to track the status of your submission.</p>
                <SubmissionDetailsComponent submission={state.iSubmission} />
                <div className="pt-3">
                  <Button className="btn" onClick={startNewSubmission} primary>Start a new submission</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThankYouSection>
  )
}

Thanks.propTypes = {
  history: PropTypes.object
}

Thanks.requiresAuth = false
