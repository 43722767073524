import React from "react"
import PropTypes from "prop-types"

import {Button, ErrorMessage} from "../styled"
import { FileInput } from "./index"


export const UploadForm = ({instructions, handleSubmit, isSubmitting, errorMessage}) => {
  return (
    <div>
      <p>{instructions}</p>
      <FileInput />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Button className="btn" primary id="btnSubmit" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? "Uploading" : "Submit"}</Button>
      <div className="container">
        <div>
          <p className="small">Promotional request form information being collected is subject to the privacy policy of T-Mobile.com.
By proceeding, I certify the information on this page is true and accurate and agree to receive text messages about my rebate (which could include info about rate plan upgrades and other steps required to become eligible).</p>
        </div>
      </div>
    </div>
  )
}

UploadForm.propTypes = {
  instructions: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
}
