import BaseApi from "./BaseApi"
const SUBMISSION_ENDPOINT = "/submission"

class SubmissionApi extends BaseApi {

  async getSubmissionsByPhoneAndCode(lookupValues) {
    try {
      const endpoint = `${SUBMISSION_ENDPOINT}/lookup`
      const response = await this.post(endpoint, lookupValues)
      return response.data
    } catch (error) {
      throw new Error("Unable to retreive submissions")
    }
  }

  async submissionForNonTMOUser(lookupValues,iSubmission, CampaignToken) {
    try {
      const params = {
        "iSubmission":iSubmission,
        "Campaign": lookupValues,
        "CampaignToken": CampaignToken
    };
      const endpoint = `${SUBMISSION_ENDPOINT}/submissionv2`
      const response = await this.post(endpoint, params)
      return response.data
    } catch (error) {
      throw new Error("Unable to retreive submissions")
    }
  }

  async orderStatusLookup(lookupValues) {
    try {
      const endpoint = `${SUBMISSION_ENDPOINT}/sslookup`
      const response = await this.post(endpoint, lookupValues)
      return response.data
    } catch (error) {
      throw new Error("Unable to retreive submissions")
    }
  }

  async insert(submissionValues) {
    try {
      const response = await this.post(SUBMISSION_ENDPOINT, submissionValues)
      return response.data
    } catch (error) {
      throw new Error("Unable to create submission")
    }
  }

  async update(iSubmission) {
    try {
      const response = await this.put(SUBMISSION_ENDPOINT, iSubmission)
      return response.data
    } catch (error) {
      throw new Error("Unable to update submission")
    }
  }

  async upload(files, iSubmitId) {
    try {
      const data = new FormData()
      files.forEach((file) => data.append("files", file))
      data.append("files", files)

      const endpoint = `${SUBMISSION_ENDPOINT}/${iSubmitId}/upload`
      const response = await this.post(endpoint, data)
      return response.data
    } catch (error) {
      throw new Error("Unable to upload files")
    }
  }

  async finalize(iSubmission) {
    try {
      const endpoint = `${SUBMISSION_ENDPOINT}/${iSubmission.iSubmitId}/finalize`
      const response = await this.post(endpoint, iSubmission)
      return response.data
    } catch (error) {
      throw new Error("Unable to finalize submission")
    }
  }
}

export default new SubmissionApi()
