import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { HeaderComponent } from "./HeaderComponent"
import { FooterComponent } from "./FooterComponent"

const addUnavScript = () => {
  const script = document.createElement("script")
  script.src = `${process.env.REACT_APP_TMO_UNAV_ENV}/client/unav.min.js`
  script.async = true
  script.type = "text/javascript"
  document.body.appendChild(script)
}


export const LayoutComponent = ({children}) => {
  useEffect(() => {
    addUnavScript()
  }, [])

  return (
    <>
      <HeaderComponent />
        {children}
      <FooterComponent />
    </>
  )
}

LayoutComponent.propTypes = {
  children: PropTypes.element.isRequired
}
