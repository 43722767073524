import React, {useContext} from "react"
import PropTypes from "prop-types"
import {withRouter} from "react-router-dom"

import {AppContext} from "../components/context/AppContext"
import { Button } from "./styled"

import { getDateAsString} from "../utils/Helpers"
import {ROUTE_PATHS} from "../utils/routes"

export const TrackerRowComponent = withRouter(({submission, history}) => {
  const { dispatch } = useContext(AppContext)
  const handleRowClick = (submission) => {
    dispatch({type: "SETSELECTEDSUBMISSION", payload: submission})
    history.push(`${ROUTE_PATHS.STATUS}`)
  }

  return (
    <tr>
      <td style={{textAlign: "center"}}>
        <Button onClick={(e) => handleRowClick(submission, e)} className="btn" primary>View</Button>
      </td>
      <td data-label="Description">{submission.offer.description}</td>
      <td data-label="Offer Value">{submission.offer.offerValue}</td>
      <td data-label="Offer Date">{getDateAsString(submission.offer.offerDate)}</td>
    </tr>
  )
})

TrackerRowComponent.propTypes = {
  submission: PropTypes.object.isRequired,
}


