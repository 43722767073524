import React from "react"
import PropTypes from "prop-types"
import {Fade} from "reactstrap"

import { MagentaSection, Button, ErrorMessage } from "./styled"
import { StatusLookupForm } from "./forms"

export const StatusLookupComponent = ({onSubmit, isReCaptchaVisible, error}) => {
  const fadeIn = false
  return (
    <section>
      <div className="row no-gutters2 mx-0">
        <MagentaSection className="container-fluid py-5">
          <div className="container">
            <div className="row no-gutters2">
              <div className="container hero3">
                {!fadeIn &&
              <Fade in={!fadeIn}>
              <h1 className="text-center mb-3">Check your submission status</h1>
              <div className="text-center">
                <div className="dropdown">
                  <Button reversed className="btn dropdown-toggle" data-toggle="dropdown">Check status</Button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href={process.env.REACT_APP_TMO_TFB_ID_AUTH_URL_TRACKER}>T-Mobile For Business</a>
                    <a className="dropdown-item" href={process.env.REACT_APP_TMO_ID_AUTH_URL_TRACKER}>T-Mobile Customer</a>
                  </div>
                </div>
              </div>
            </Fade>
                }
                {fadeIn &&
                <Fade in={fadeIn} timeout={300}>
                  <h1 className="text-center">Enter Your Info</h1>
                  <StatusLookupForm onSubmit={onSubmit} isReCapthcaVisible={isReCaptchaVisible} />
                </Fade>
                }
                {error &&
                <ErrorMessage secondary className="text-center">{error}</ErrorMessage>
                }
              </div>
            </div>
          </div>
        </MagentaSection>
      </div>
    </section>
  )
}

StatusLookupComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isReCaptchaVisible: PropTypes.bool.isRequired,
  error: PropTypes.string
}
