import styled from "styled-components"


export const ThankYouSection = styled.section`
  background-image: url("/images/thanks_mobile.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  @media (min-width: 768px)  {
    background-image: url("/images/thanks_desktop.png");
}

`
