import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"

import {ROUTE_PATHS} from "../utils/routes"
import {getDateAsString, getSubmissionStatusAsString} from "../utils/Helpers"
import {AuthApi} from "../utils/api"

import {AppContext} from "../components/context/AppContext"
import {VirtualCardComponent} from "../components"
import {Button} from "../components/styled"
import { ErrorMessage } from "formik"
import AnalyticsService from "../utils/AnalyticsService"


export const StatusDetail = ({location}) => {
  const {state, dispatch} = useContext(AppContext)
  const {selectedSubmission} = state
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get("code");
  const isFromStatusPage = true

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.STATUS);
  }, [state.lookupValues.phoneNumber]);

  useEffect(() => {
    const redeemTmo = async () => {
      setIsLoading(true)

      if (state.user.ban) {
        setIsLoading(false)
        return
      }

      if (!code) {
        setIsLoading(false)
        return
      }

      try {
        if (code) {
          const user = await AuthApi.loginWithTmoId(code, state.lookupValues.phoneNumber, isFromStatusPage )
          dispatch({type: "SETUSER", payload: user})
        }
      } catch (error) {
        setErrorMessage("Unable to login with T-Mobile, please try again.")
        setIsLoading(false)
      }
    }

    redeemTmo()
  }, [code, dispatch, state.user.ban, state.lookupValues.phoneNumber, isFromStatusPage])

  let nonCompMessageList
  if (selectedSubmission.offerErrors) {
    nonCompMessageList = selectedSubmission.offerErrors.map((message) => <li key={message.errorId}>{message.nonCompMessage}</li>)
  }

  return (
    <section>
      <div className="container hero3">
        <div className="row no-gutters2">
          <div className="col-md-12">
            <h1 className="magenta">Reward Tracker</h1>
            <h3>
              {!selectedSubmission.error && selectedSubmission.statusMessage !== "" && selectedSubmission.statusMessage}
            </h3>
            <h3>{nonCompMessageList && nonCompMessageList.length > 0 &&
              <div>Your submission has been denied. Here&apos;s why:
                <ul>{nonCompMessageList}</ul>
              </div> }
            </h3>
            <p><strong>Offer Information</strong></p>
            <p>
              <strong>Offer Number: </strong>{selectedSubmission.offerId}<br />
              <strong>Offer Description: </strong>{selectedSubmission.offer.description}<br />
              <strong>Offer Date Range: </strong>{`${getDateAsString(selectedSubmission.offer.startDate)} - ${getDateAsString(selectedSubmission.offer.endDate)}`}
            </p>
            <p><strong>Customer Information</strong></p>
            <p>
              <strong>Tracking ID: </strong>{selectedSubmission.trackingId}<br />
              <strong>Submission Date: </strong>{getDateAsString(selectedSubmission.stampDate)}
            </p>
            <p><strong>Reward Information</strong></p>
            <p>
              <strong>Reward Type: </strong>
              {selectedSubmission.offer.isDiscountOffer ? "Discount" : "Virtual Prepaid Card"}
              <br />
              <strong>Status:</strong> {getSubmissionStatusAsString(selectedSubmission)}
            </p>
            {isLoading && <div>Logging In ...</div>}
            {!isLoading &&
            <div className="pt-3 text-center text-md-left">
              {selectedSubmission.offer.isRebateOffer
                 && getSubmissionStatusAsString(selectedSubmission) === "Sent"
                 && <VirtualCardComponent selectedSubmission={selectedSubmission} user={state.user}/>
              }
              {state.submissions.length > 0
                ? <Link to={ROUTE_PATHS.TRACKER}><Button style={{cursor: "pointer"}} className="ml-md-3" secondary>Back</Button></Link>
                : <Link to={ROUTE_PATHS.HOME}><Button style={{cursor: "pointer"}} className="ml-md-3" secondary>Back</Button></Link>}
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </div>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

StatusDetail.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

StatusDetail.requiresAuth = false
