import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"

import {LookupForm, ResendCodeForm} from "../components/forms"
import {LookupResponseComponent} from "../components"
import {LookupApi, EmailApi, OfferApi} from "../utils/api"
import {ErrorMessage} from "../components/styled"
import AnalyticsService from "../utils/AnalyticsService"
import {ROUTE_PATHS} from "../utils/routes"

export const Lookup = () => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isRecaptchaVisible, setIsRecaptchaVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [resendErrorMessage, setResendErrorMessage] = useState()
  const [hookupCode, setHookupCode] = useState()
  const [offerInfo, setOfferInfo] = useState()
  const [isSuccess, setSuccess] = useState()

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.LOOKUP);
     
    const getCaptchaVisibility = async () => {
      const result = await OfferApi.getClientReCaptchaVisible()
      setIsRecaptchaVisible(result)
    }
    getCaptchaVisibility()
  }, []);

  const resetState = () => {
    setErrorMessage("")
    setResendErrorMessage("")
    setSuccess(false)
    setHookupCode(null)
    setOfferInfo(null)
  }

  const toggleModal = (e) => {
    e.preventDefault()
    resetState()
    setModalVisible(!isModalVisible)
    if(e.target.innerText === 'click here.' ){
      AnalyticsService.sendGa4Event({event: "hookup_lookup",category:"click_link",action:"exclusive_code",label:"Lookup"})
    }
  }

  const submitExclusiveCode = async (values) => {
    resetState()
    try {
      let response
      response = await LookupApi.getHookupCode(values)
      setHookupCode(response)
      response = await LookupApi.getCampaignInfo(values)
      setOfferInfo(response)
      AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"click_button",action:"check_status",label:"code"})
     } catch (error) {
      switch (error.message) {
      case "Hookup code not found":
        setErrorMessage("There were no codes that matched your search criteria.")
        break
      default:
        setErrorMessage(error.message)
        break
      }
    }
  }

  const submitResendCode = async (values, {setSubmitting}) => {
    try {
      const response = await EmailApi.sendCodeResendEmail(values)
      if (response.status === "200") {
        setSuccess(true)
        setModalVisible(!isModalVisible)
      } else if (response === "Hookup code not found") {
        setResendErrorMessage("No codes were found with that email address.")
        setSubmitting(false)
      }
      AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"Lookup",action:"click",label:"code"})
    } catch (error) {
      switch (error.message) {
      case "Hookup code not found":
        setResendErrorMessage("No codes were found with that email address.")
        setSubmitting(false)
        break
      default:
        setResendErrorMessage(error.message)
        setSubmitting(false)
        break
      }
    }
  }

  return (
        <>
            <section>
              <div className="container hero3">
                <div className="row no-gutters2">
                  <div className="col">
                    <h1 className="magenta">Check the status of your exclusive code</h1>
                    <p>To begin, simply enter the exclusive code you were provided. If you do not know your exclusive code, <Link to="#" onClick={toggleModal}>click here.</Link></p>
                  </div>
                </div>
                <div className="container">
                  <div className="row no-gutters2">
                    <div className="mx-auto col-md-12">
                      <LookupForm onSubmit={submitExclusiveCode} isReCaptchaVisible={isRecaptchaVisible}/>
                    </div>
                  </div>
                  {errorMessage
                    ? <ErrorMessage>{errorMessage}</ErrorMessage>
                    : hookupCode && offerInfo
                            && <LookupResponseComponent hookupCode={hookupCode} offerName={offerInfo.offerName}/>}
                  {isSuccess &&
                        <div className="row no-gutters2">
                          <div className="col">
                            <h1 className="magenta">Thank you for your request.</h1>
                            <p>We&apos;ve sent your exclusive codes.</p>
                          </div>
                        </div>}
                </div>
              </div>
              <ResendCodeForm
                isModalVisible={isModalVisible}
                toggleModal={toggleModal}
                onSubmit={submitResendCode}
                error={resendErrorMessage}/>
            </section>
        </>
  )
}
