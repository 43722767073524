import React from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import {Formik, Form, Field, ErrorMessage} from "formik"

import {TextInput, ReCAPTCHAInput} from "../forms"
import {Button} from "../styled"

import {validatePhone, addOptionalReCaptchaSchema, validZipCodeRegEx} from "../../utils/FormUtils"

const initialValues = {
  phoneNumber: "",
  zipCode: "",
  recaptcha: ""
}

let constantSchema = {
  zipCode: Yup
    .string()
    .required("Zip Code is required.")
    .min(5, "Zip Code must be at least five digits.")
    .max(5, "Zip Code must be at least five digits.")
    .matches(validZipCodeRegEx, "Zip Code is invalid.")
}

export const StatusLookupForm = ({isReCapthcaVisible, onSubmit}) => {
  const validationSchema = addOptionalReCaptchaSchema(constantSchema, isReCapthcaVisible)
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({status, isSubmitting}) => (
        <Form>
          <p className="text-center">Fields marked with * are required.</p>
          <div className="row no-gutters2 mb-3">
            <div className="form-group col-md-6">
              <Field
                name="phoneNumber"
                component={TextInput}
                validate={validatePhone}
                type="text"
                placeholder="Phone Number*"
                secondary />
            </div>
            <div className="form-group col-md-6">
              <Field
                name="zipCode"
                component={TextInput}
                type="text"
                placeholder="ZipCode*"
                maxLength="5"
                secondary />
            </div>
          </div>
          {isReCapthcaVisible &&
          <div className="row no-gutters2 mb3">
            <div className="form-group col-md-12">
              <Field
                name="recaptcha"
                component={ReCAPTCHAInput}
                secondary
              />
            </div>
          </div>
          }
          <div className="mt-3 text-center">
            <Button
              reversed
              type="submit"
              className="btn"
              id="btnSubmit"
              disabled={isSubmitting}
            >{isSubmitting ? "Searching" : "Continue"}</Button>
            {status && status.formError && <ErrorMessage secondary>{status.formError}</ErrorMessage>}
          </div>
        </Form>
      )}
    </Formik>
  )
}

StatusLookupForm.propTypes = {
  isReCapthcaVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}
