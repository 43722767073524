/*
 * Copyright 2019 T-Mobile US, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
  @module: tmo-poptoken-builder
  @description: Defines the functions for the PoP Token Builder.
  @version: 1.0
**/
var rs = require("jsrsasign");

export function generatePopToken(config) {
  if (sessionStorage.getItem("pvtkey") === null) {
    var keypair = rs.KEYUTIL.generateKeypair("RSA", 1024);
    sessionStorage.setItem("pubkey", rs.KEYUTIL.getPEM(keypair.pubKeyObj));
    sessionStorage.setItem(
      "pvtkey",
      rs.KEYUTIL.getPEM(keypair.prvKeyObj, "PKCS1PRV")
    );
  }

  checkIfAnonymousTokenExpired();

  var isAnonymousRequest = checkIfAnonymousRequest(config.url);

  var ehtsKeyValuMap = new Map();
  var authorizationValue = isAnonymousRequest
    ? sessionStorage.getItem("anonymous_token")
    : sessionStorage.getItem("TMO_PROMO_TOKEN_KEY");

  ehtsKeyValuMap.set("Authorization", "Bearer " + authorizationValue);
  ehtsKeyValuMap.set("http-method", config.method.toLocaleUpperCase());

  var popToken = buildPopToken(ehtsKeyValuMap);

  if (isAnonymousRequest) {
    //if(true)
    config.headers.common["Authorization"] =
      "Bearer " + sessionStorage.getItem("anonymous_token");
  }

  config.headers.common["X-Authorization"] = popToken;

  return config;
}

function checkIfAnonymousTokenExpired() {
  var existingAnonymousToken = sessionStorage.getItem("anonymous_token");

  const expirationTime = existingAnonymousToken
    ? JSON.parse(window.atob(existingAnonymousToken.split(".")[1])).exp
    : 0;
  var isTokenExpired = Date.now() >= expirationTime * 1000;

  if (!existingAnonymousToken || isTokenExpired) {
    //if (true) {
    requestAnonymousToken();
  }
}

const requestAnonymousToken = () => {
  var megClientId = process.env.REACT_APP_MEG_CLIENT_ID;
  var anonymousTokenUrl = process.env.REACT_APP_ANONYMOUS_TOKEN_URL;
  var ehtsKeyValueMapForPop = new Map();
  ehtsKeyValueMapForPop.set("http-method", "POST");

  var popForAnonymousTokenCall = buildPopToken(ehtsKeyValueMapForPop);

  var inputData = {
    client_id: megClientId,
    cnf: sessionStorage.getItem("pubkey"),
  };

  const xhr = new XMLHttpRequest();
  xhr.open("POST", anonymousTokenUrl, false);
  xhr.setRequestHeader("Content-type", "application/json");
  xhr.setRequestHeader("X-Authorization", popForAnonymousTokenCall);
  // Make sure to stringify
  xhr.onload = function () {
    var response = JSON.parse(xhr.responseText);
    //alert(response);
    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
      // Request finished. Do processing here.
      sessionStorage.setItem("anonymous_token", response.access_token);
      console.log("TOKEN SET");
    }
  };
  xhr.onerror = function () {
    sessionStorage.removeItem("anonymous_token");
  };

  xhr.send(JSON.stringify(inputData));
};

export function checkIfAnonymousRequest(url) {

  var anonymousRequestURI = [
    "/auth/code",
    "/auth/msisdnLineStatusLookup/",
    "/auth/subscriberStatusCode",
    "/email/code-resend",
    "/hookup-lookup/lookup",
    "/hookup-lookup/campaignInfo",
    "/hookup-lookup/campaign",
    "/faq",
    "/clientrecaptchavisible",
    "/submission/osl",
    "submission/submissionv2",
  ];

  if (url.split("/").some((el) => el === "asset")) {
    return true;
  }

  return anonymousRequestURI.some((uri) => url.includes(uri.toLowerCase()));
}

export function buildPopToken(ehtsKeyValuMap) {
  /*
     param ehtsKeyValuMap - Map to be signed
     param privateKeyPemString - Private Key PEM string
    */
  var objClaim = {};
  var strEhts = "";
  var strToHash = "";
  var strToken = "";
  var curTime = "now";
  var currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
  var expTime = currentTimeInSeconds + 2 * 60; // token is valid for 2 minutes

  for (var [key, value] of ehtsKeyValuMap) {
    if (strEhts.length > 0) {
      strEhts = strEhts + ";";
    }
    strEhts = strEhts + key;
    strToHash = strToHash + value;
  }

  var strHash = rs.KJUR.crypto.Util.sha256(strToHash);
  var strEdtsHashB64U = rs.hextob64u(strHash);

  var strUniq = createGUID();

  if (strEdtsHashB64U.length > 0) {
    objClaim.edts = strEdtsHashB64U;
  }

  objClaim.v = "1";

  if (expTime !== "") {
    objClaim.exp = expTime;
  }

  if (strEhts.length > 0) {
    objClaim.ehts = strEhts;
  }

  if (curTime !== "") {
    objClaim.iat = rs.KJUR.jws.IntDate.get(curTime);
  }

  if (strUniq.length > 0) {
    objClaim.jti = strUniq;
  }

  var sClaim = JSON.stringify(objClaim);
  var alg = "RS256";
  var pHeader = { alg: alg, typ: "JWT" };
  var sHeader = JSON.stringify(pHeader);
  var privateKeyPemString = sessionStorage.getItem("pvtkey");

  strToken = rs.KJUR.jws.JWS.sign(null, sHeader, sClaim, privateKeyPemString);

  return strToken;
}

export function createGUID() {
  function randomStr() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    randomStr() +
    randomStr() +
    "-" +
    randomStr() +
    "-" +
    randomStr() +
    "-" +
    randomStr() +
    "-" +
    randomStr() +
    randomStr() +
    randomStr()
  );
}
