import BaseApi from "./BaseApi"

const HOOKUP_LOOKUP_ENDPOINT = "/hookup-lookup"

class LookupApi extends BaseApi {
    async getHookupCode(values){
        try{
            const endpoint = `${HOOKUP_LOOKUP_ENDPOINT}/lookup`
            const response = await this.post(endpoint,values)
            return response.data
        } catch(error){
            const {data} = error.response
            throw new Error(data)
        }
    }

    async getCampaignInfo(values){
        try{
            const endpoint = `${HOOKUP_LOOKUP_ENDPOINT}/campaigninfo`
            const response = await this.post(endpoint,values)            
            return response.data
        } catch(error){
            const {data} = error.response
            throw new Error(data)
        }
    }

    async getCampaign(values){
        try{
            const endpoint = `${HOOKUP_LOOKUP_ENDPOINT}/campaign`
            const response = await this.post(endpoint,values)
            localStorage.setItem("CampaignToken",response.data.campaignToken);            
            return response.data
        } catch(error){
            const {data} = error.response
            throw new Error(data)
        }
    }
}

export default new LookupApi()