import * as Yup from "yup"

const validPhoneLengthRegEx = /(^$)|(^\d{10}$)/
const validSsnRegex = /^[0-9]{4}$/

export const validZipCodeRegEx = /\d{5}/

export const states = [
  {
    "name": "Alabama",
    "abbreviation": "AL"
  },
  {
    "name": "Alaska",
    "abbreviation": "AK"
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "name": "California",
    "abbreviation": "CA"
  },
  {
    "name": "Colorado",
    "abbreviation": "CO"
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "name": "Delaware",
    "abbreviation": "DE"
  },
  {
    "name": "District Of Columbia",
    "abbreviation": "DC"
  },
  {
    "name": "Florida",
    "abbreviation": "FL"
  },
  {
    "name": "Georgia",
    "abbreviation": "GA"
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "name": "Idaho",
    "abbreviation": "ID"
  },
  {
    "name": "Illinois",
    "abbreviation": "IL"
  },
  {
    "name": "Indiana",
    "abbreviation": "IN"
  },
  {
    "name": "Iowa",
    "abbreviation": "IA"
  },
  {
    "name": "Kansas",
    "abbreviation": "KS"
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "name": "Maine",
    "abbreviation": "ME"
  },
  {
    "name": "Maryland",
    "abbreviation": "MD"
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "name": "Michigan",
    "abbreviation": "MI"
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "name": "Missouri",
    "abbreviation": "MO"
  },
  {
    "name": "Montana",
    "abbreviation": "MT"
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "name": "Nevada",
    "abbreviation": "NV"
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "name": "New York",
    "abbreviation": "NY"
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "name": "Ohio",
    "abbreviation": "OH"
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "name": "Oregon",
    "abbreviation": "OR"
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "name": "Puerto Rico",
    "abbreviation": "PR"
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "name": "Texas",
    "abbreviation": "TX"
  },
  {
    "name": "Utah",
    "abbreviation": "UT"
  },
  {
    "name": "Vermont",
    "abbreviation": "VT"
  },
  {
    "name": "Virgin Islands",
    "abbreviation": "VI"
  },
  {
    "name": "Virginia",
    "abbreviation": "VA"
  },
  {
    "name": "Washington",
    "abbreviation": "WA"
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY"
  }
]

export const contactReasons = [
  "Status of Request",
  "Terms and Conditions of Offer",
  "Unqualified Letter Received",
  "Other"
]

export const handleSubmissionError = (setSubmitting, setStatus, error = "There was a problem. Please try again.") => {
  setStatus({formError: error})
  setSubmitting(false)
}

export const addOptionalRegistrationSchema = (validationObject, fields, isTMobileCustomer) => {
  if (fields) {
    const optionalFields = fields.filter(field => field.default === false && field.visible === true)
    if (optionalFields.length > 0) {
      const imei2FieldVisible = optionalFields.find(field => field.fieldName === "imei2") !== undefined

      const noMatchSchema = Yup
        .string()
        .notOneOf([Yup.ref("imei"), null], "The first and second purchased devices cannot have the same IMEI info. Please re-enter.")

      optionalFields.forEach(field => {
        switch (field.fieldName) {
        case "imei":
          validationObject.imei = Yup
            .string()
            .required(imei2FieldVisible
              ? "15-Digit IMEI # of First Purchased Device is required."
              : "15-Digit IMEI # of Purchased Device is required.")
            .min(15,imei2FieldVisible
              ? "15-Digit IMEI # of First Purchased Device is invalid."
              : "15-Digit IMEI # of Purchased Device is invalid.")
            .max(15, imei2FieldVisible
              ? "15-Digit IMEI # of First Purchased Device is invalid."
              : "15-Digit IMEI # of Purchased Device is invalid.")
          validationObject.confirmImei = Yup
            .string()
            .oneOf([Yup.ref("imei"), null],
              imei2FieldVisible
                ? "The entered 15-Digit IMEI # of First Purchased Device and Confirm First IMEI # info does not match. Please re-enter."
                : "The entered 15-digit IMEI and Confirm 15-digit IMEI info does not match. Please re-enter.")
            .required(imei2FieldVisible ? "Confirm First IMEI # is required." : "Confirm IMEI # is required.")
          break
        case "imei2":
          validationObject.imei2 = Yup
            .string()
            .required("15-Digit IMEI # of Second Purchased Device is required.")
            .min(15, "15-Digit IMEI # of Second Purchased Device is invalid.")
            .max(15, "15-Digit IMEI # of Second Purchased Device is invalid.")
            .concat(imei2FieldVisible ? noMatchSchema : null)
          validationObject.confirmImei2 = Yup
            .string()
            .oneOf([Yup.ref("imei2"), null], "The entered 15-Digit IMEI # of Second Purchased Device and Confirm Second IMEI # info does not match. Please re-enter. ")
            .required("Confirm Second IMEI # is required.")
          break
        case "dealerCode":
          validationObject.dealerCode = Yup
            .string()
            .required("Dealer Code is required.")
          break
        case "lastFourSsn":
          if (!isTMobileCustomer) {
            validationObject.lastFourSsn = Yup
              .string()
              .required("Last Four of SSN or Tax ID is required.")
              .matches(validSsnRegex, "Last Four of SSN is invalid.")
          }
          break
        default:
          break
        }
      })
    }
  }
  return Yup.object().shape(validationObject)
}

export const addOptionalReCaptchaSchema = (validationObject, recaptchaVisible) => {
  if (recaptchaVisible) {
    validationObject.recaptcha = Yup.string().nullable().required("Please check the captcha")
  }

  return Yup.object().shape(validationObject)
}

export const validatePhone = (value) => {
  let error
  if (!value) {
    error = "Phone Number is required"
  }

  if (value && !value.replace(/[^a-z0-9]/g, "").match(validPhoneLengthRegEx)) {
    error = "Phone Number must be 10 digits"
  }

  /* eslint-disable eqeqeq */
  // we need double equal here
  if (value && (value.charAt(0) == 1 || value.charAt(0) == 0)) {
    error = "Phone Number cannot start with 0 or 1"
  }

  return error
}

export const formatPhoneNumber = (phoneNumber) => phoneNumber.replace(/[^a-z0-9]/g, "")
