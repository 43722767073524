import React from "react"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"

import {ErrorMessage} from "../styled"

export const ReCAPTCHAInput = ({
  field,
  form: {touched, errors, setFieldValue},
  secondary,
  ...props
}) => {
  return (
    <div className="recaptcha-container mb-3">
      <ReCAPTCHA
        sitekey={ process.env.REACT_APP_RECAPTCHA_SITE_KEY || "" }
        {...field}
        {...props}
        onChange={(val) => { setFieldValue("recaptcha", val) }}
      />
      {touched[field.name] && errors[field.name] && <ErrorMessage secondary={secondary}>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

ReCAPTCHAInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  secondary: PropTypes.bool
}
