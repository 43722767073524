import React from "react"
import PropTypes from "prop-types"
import { ExclusiveCodeForm } from "./forms"
import { ErrorMessage } from "./styled"

export const ExclusiveCodeComponent = ({
  onSubmit,
  isReCaptchaVisible,
  error,
  exclusiveCode, }) => {
  return (
    <div className="container">
      <div className="row no-gutters2">
        <div className="mx-auto col-md-4">
          {error
            ? <ErrorMessage>{error}</ErrorMessage>
            : <ExclusiveCodeForm onSubmit={onSubmit} isReCaptchaVisible={isReCaptchaVisible} exclusiveCode={exclusiveCode}
            />
          }
        </div>
      </div>
    </div>
  )
}

ExclusiveCodeComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isReCaptchaVisible: PropTypes.bool,
  error: PropTypes.string
}
