// eslint-disable-next-line
import React, {useState, useContext, useEffect} from "react"
import PropTypes from "prop-types"

import {AppContext} from "../components/context/AppContext"
import {QuestionsAndContactComponent} from "../components"
import {RegistrationForm} from "../components/forms"

import {SubmissionApi } from "../utils/api"
import {handleSubmissionError} from "../utils/FormUtils"
import {ROUTE_PATHS} from "../utils/routes"
import {validateImei} from "../utils/Helpers"
import AnalyticsService from "../utils/AnalyticsService"

export const Register = (props) => {
  const {state, dispatch} = useContext(AppContext)

  /* Backend
    Reserved = 1,
    Registered = 2,
    Consumed = 3,
    Expired = 4,
    Released = 5
  */
 // eslint-disable-next-line
  const STATUS_ID = {
    PENDING: 1,
    REGISTERED: 2
  }
  
  useEffect(() => {    
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.REGISTER);
    
    //if (state.iSubmission && state.iSubmission.statusId !== 4) {
    //  props.history.push(ROUTE_PATHS.HOME)
    //}
  });
  
  const getPreviousSubmission = async (values) => {    
    let response
    state.lookupValues.phoneNumber = values.phone

    response = await SubmissionApi.getSubmissionsByPhoneAndCode(state.lookupValues)

    const {submission, iSubmission, hookupCodeSubmission} = response    
    if (hookupCodeSubmission !== null) {      
      if (hookupCodeSubmission.hasRegisteredSubmission) {
        state.showSubmitButton = true
        state.iSubmission = iSubmission
        dispatch({type: "SETISUBMISSION", payload: iSubmission})
      }

      if (hookupCodeSubmission.hasCompletedSubmission) {
        sessionStorage.setItem("showSubmitButton", false)
        state.showSubmitButton = false
        dispatch({type: "SETSELECTEDSUBMISSION", payload: submission || iSubmission})
      } else {
        state.showSubmitButton = true
        sessionStorage.setItem("showSubmitButton", true)
      }
    }
  }
    

  const validateImeis = (values) => {
    const imeiErrors = []
    let imie2Visible = false

    if (values.imei2) {
      imie2Visible = true // two is checked first to determine error message for one
      const imeiIsValid = validateImei(values.imei2)
      if (!imeiIsValid) {
        imeiErrors.push({field: "imei2", message: "15-Digit IMEI # of Second Purchased Device is invalid."})
      }
    }

    if (values.imei) {
      const imeiIsValid = validateImei(values.imei)
      if (!imeiIsValid) {
        const message = imie2Visible
          ? "15-Digit IMEI # of First Purchased Device is invalid."
          : "15-Digit IMEI # of Purchased Device is invalid."
        imeiErrors.push({field: "imei", message} )
      }
    }

    return imeiErrors
  }

  const submitRegistrationForm = async (values, {setSubmitting, setStatus, setFieldError}) => {
    AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"Register",action:"Submit",label:"Upload_Site"})
    if (!state.lookupValues.isTMobileCustomer) {
      let responseData

      const {promoCode} = state.lookupValues
      const submissionValues = {...values, promoCode}

      state.lookupValues.phoneNumber = values.phone
      responseData = await SubmissionApi.submissionForNonTMOUser(state.lookupValues, submissionValues, localStorage.getItem("CampaignToken"))      
      if (!responseData.iSubmitId && responseData.ineligibleReason) {
        handleSubmissionError(setSubmitting, setStatus, responseData.ineligibleReason)
        return
      }
      const {submission, iSubmission, hookupCodeSubmission} = responseData

      if (hookupCodeSubmission !== null) {
        if (hookupCodeSubmission.hasRegisteredSubmission) {
          state.showSubmitButton = true
          dispatch({type: "SETISUBMISSION", payload: iSubmission})
        }
  
        if (hookupCodeSubmission.hasCompletedSubmission) {
          sessionStorage.setItem("showSubmitButton", false)
          state.showSubmitButton = false
          dispatch({type: "SETSELECTEDSUBMISSION", payload: submission || iSubmission})
        } else {
          state.showSubmitButton = true
          sessionStorage.setItem("showSubmitButton", true)
        }
      }
      if (state.showSubmitButton) {
        props.history.push(ROUTE_PATHS.THANKS)
        return
      }  
   
    } else {
      await getPreviousSubmission(values)      
      try {
        const imeiErrors = validateImeis(values)
        if (imeiErrors.length > 0 ) {
          imeiErrors.forEach(error => setFieldError(error.field, error.message))
          setSubmitting(false)
          return
        }
  
        const offerId = state.campaign.offer.offerId
        const { promoCode, phoneNumber: telephone1, isTMobileCustomer } = state.lookupValues
        const { b2bOrg, userId, isTFBAccount, tfbRole } = state.user
        const submissionValues = { ...values, offerId, promoCode, telephone1, isTMobileCustomer, b2bOrg, userId, isTFBAccount, tfbRole }
        const iSubmission = state.iSubmission
        let response 

        if (state.showSubmitButton) {          
          if (state.iSubmission && state.iSubmission.iSubmitId) {

            iSubmission.telephone1 = telephone1
    
            response = await SubmissionApi.update({...iSubmission, ...submissionValues})
    
            if (!response.iSubmitId && response.ineligibleReason) {
              handleSubmissionError(setSubmitting, setStatus, response.ineligibleReason)
              return
            }
          } else {

              response = await SubmissionApi.insert(submissionValues)
            
              if (!response.iSubmitId && response.ineligibleReason) {
                handleSubmissionError(setSubmitting, setStatus, response.ineligibleReason)
                return
              }
          }
          
          dispatch({type: "SETISUBMISSION", payload: response})
          AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"Register",action:"click",label:"submit_form"})
          if (state.campaign.isUploadsAllowed) {
            setSubmitting(false)
            props.history.push(ROUTE_PATHS.UPLOAD)
            return
          }

          const result = await SubmissionApi.finalize(response)
          
          dispatch({type: "SETISUBMISSION", payload: result})
  
          setSubmitting(false)
  
          AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"Register",action:"Submit",label:"Upload_Site"})
        
          if (state.showSubmitButton) {
            props.history.push(ROUTE_PATHS.THANKS)
            return
          }  
        } else {
          return
        }
        
      } catch (error) {
        handleSubmissionError(setSubmitting, setStatus)
        return
      }
    }
   
  }

  return (
    <section>
      <div className="container hero3">
        <div className="row no-gutters2">
          <div className="col-md-12">
            <h1 className="magenta font-teleNeoWeb">Confirm your details</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row no-gutters2">
          
              <div className="mx-auto col-md-12">
                <RegistrationForm onSubmit={submitRegistrationForm} fields={state.campaign.offer.registrationFields} isTMobileCustomer={state.lookupValues.isTMobileCustomer}
                  hintOffer={state.campaign.name.toUpperCase().includes("HINT")} />
                <QuestionsAndContactComponent />
              </div>

        </div>
      </div>
    </section>
  )
}

Register.propTypes = {
  history: PropTypes.object
}

Register.requiresAuth = false
