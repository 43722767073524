import React from "react"

export const WelcomeComponent = () => {
  return (
    <div className="container-fluid">
      <div className="row px-0">
        <div className="col-12 d-md-block d-none px-0 mx-auto w-100">
            <img src="/images/header_with_text_desktop.png" className="img-fluid" width="100%" alt=""/>
        </div>
        <div className="col-12 d-md-none d-block px-0 mx-auto w-100">
          <img src="/images/header_with_text_mobile.png" className="img-fluid" width="100%" alt=""/>
        </div>
      </div>
    </div>
  )
}
