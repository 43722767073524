import BaseApi from "./BaseApi"

const AUTH_ENDPOINT = "/auth"

class AuthApi extends BaseApi {

  getLocalDnsValue() {
    const allCookies = document.cookie

    if (!allCookies
      .split(";")
      .filter((cookie) => {
        return cookie.trim().indexOf("dnt=") === 0
      }).length) {
      return "0" // no cookie equals "Sell"
    }

    const localDnsCookie = allCookies
      .split(";")
      .filter((cookie) => {
        return cookie.trim().indexOf("dnt=") === 0
      })[0]

    return localDnsCookie
      .substring(localDnsCookie.indexOf("=") + 1, localDnsCookie.length) === "1"
      ? "1"
      : "0"
  }

  async loginWithPhoneNumber(formValues) {
    try {
      const endpoint = `${AUTH_ENDPOINT}/phonenumber`
      const response = await this.post(endpoint, formValues)
      const {data: user} = response
      this.setAuthToken(user.userToken)

      return user

    } catch (error) {
      throw new Error("Unable to log in with phone number")
    }
  }

  async loginWithTmoId(code, phoneNumber, sessionNumber, pubKey,state , isFromStatusPage = false) {
    try {
      const endpoint = `${AUTH_ENDPOINT}/code`
      const response =  await this.post(endpoint, {code, phoneNumber, sessionNumber, pubKey,state, isFromStatusPage})

      const {data: user} = response
      
      this.setAuthToken(user.userToken)

      return user

    } catch (error) {
      const {data} = error.response
      throw new Error(data)
    }
  }

  async getSubscriberStatusCode(formValues) {
    try {
      const endpoint = `${AUTH_ENDPOINT}/subscriberstatuscode/`
      const response = await this.post(endpoint, formValues)
      const {data} = response

      return data
    } catch (error) {
      throw new Error("Unable to retrieve status code")
    }
  }

  async getGlobalDnsSetting(tMobileId) {
    try {
      const dnsValue = this.getLocalDnsValue
      const endpoint = `${AUTH_ENDPOINT}/dnssetting`
      const response = await this.post(endpoint, {tMobileId: tMobileId, doNotSellValue: dnsValue})
      const {data: dns} = response

      return dns
    } catch (error) {
      throw new Error("Unable to retrieve DNS setting")
    }
  }

  async getGlobalDnsSettingV2(tMobileId) {
    try {
      const dnsValue = this.getLocalDnsValue()
      const endpoint = `${AUTH_ENDPOINT}/getdnssettingv2`
      const response = await this.post(endpoint, {tMobileId: tMobileId, doNotSellValue: dnsValue})
      const {data: dnsV2} = response

      return dnsV2
    } catch (error) {
      throw new Error("Unable to retrieve DNS setting")
    }
  }

  async setGlobalDnsSetting(tMobileId, dnsValue){
    try {
      const endpoint = `${AUTH_ENDPOINT}/setdnssetting`
      const response = await this.post(endpoint, {tMobileId: tMobileId, doNotSellValue: dnsValue})
      return response
    } catch (error) {
      throw new Error("Unable to set the DNS setting")
    }
    
  }

  async getLineStatusByMsisdn(msisdn) {
    try {
      const endpoint = `${AUTH_ENDPOINT}/msisdnlinestatuslookup/${msisdn}`
      const response = await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve the requested asset")
    }
  }



}

export default new AuthApi()
