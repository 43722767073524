import React, {useEffect} from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import {Formik, Form, Field} from "formik"

import {TextInput, ReCAPTCHAInput} from "."
import {Button, ErrorMessage} from "../styled"
import {addOptionalReCaptchaSchema} from "../../utils/FormUtils"

const initialValues = {
  phoneNumber: "",
  promoCode: "",
  recaptcha: ""
}

let constantSchema = {
  promoCode: Yup.string().required("Exclusive Code is required")
}

export const ExclusiveCodeForm = ({onSubmit, isReCaptchaVisible, exclusiveCode}) => {
  const validationSchema = addOptionalReCaptchaSchema(constantSchema, isReCaptchaVisible)
  
  useEffect(() => {
    initialValues.promoCode = exclusiveCode    
  }, [exclusiveCode])

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({status, isSubmitting}) => (
        <Form>
          <p className="">Fields marked with * are required.</p>
          <div className="row no-gutters2 mb-3">
            
            <div className="form-group col-md-12">
              <Field name="promoCode" component={TextInput} type="text" placeholder="Exclusive Code*"></Field>
            </div>
          </div>
          {isReCaptchaVisible &&
              <div className="row no-gutters2">
                <div className="col-md-12">
                  <Field name="recaptcha" component={ReCAPTCHAInput} />
                </div>
              </div>
          }
          <div className="mt-3 text-center">
            <Button
              type="submit"
              className="btn"
              id="btnSubmit"
              primary
              disabled={isSubmitting }>{isSubmitting ? "Searching" : "Submit"}</Button>
            {status && status.formError && <ErrorMessage>{status.formError}</ErrorMessage>}
          </div>
        </Form>
      )}
    </Formik>

  )
}

ExclusiveCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isReCaptchaVisible: PropTypes.bool
}
