import BaseApi from "./BaseApi"

const PROMOTION_ADMIN_ENDPOINT = "promo-admin"
const HOOKUP_CLIENT_ID = "1044"


class OfferApi extends BaseApi {

  async getOfferDetails(lookupValues) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}/lookup`
      const response =  await this.post(endpoint,lookupValues)
      return response.data
    } catch (error) {
      const {data} = error.response
      if (data === "A promotion with that code does not exist" || data === "Hookup code not found" ) {
        throw new Error(data)
      }

      throw new Error("Unable to retrieve offer details")
    }
  }

  async getClientFaqs() {
    const clientId = process.env.REACT_APP_FAQ_CLIENT_ID
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}/${clientId}/faq`
      return await this.get(endpoint)
    } catch (error) {
      throw new Error("Unable to retrieve client faq")
    }
  }

  async getClientReCaptchaVisible() {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}/clientrecaptchavisible`
      const response =  await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve client recaptcha visibility")
    }
  }

  async getAssetsByKeyword(keyword) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}/${HOOKUP_CLIENT_ID}/asset/${keyword}`
      const response =  await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve the requested asset")
    }
  }

  async getAssetsByKeywordHookup(keyword) {
    try {
      const clientId = process.env.REACT_APP_FAQ_CLIENT_ID
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}/${clientId}/asset/${keyword}`
      const response =  await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve the requested asset")
    }
  }
}

export default new OfferApi()
