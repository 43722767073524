import React from "react"
import PropTypes from "prop-types"
import { getVirtualPayRedemptionLink } from "../utils/Helpers"
import { LinkButton } from "./styled"


export const VirtualCardComponent = ({selectedSubmission, user}) => {

  const login = (e) => {
    e.preventDefault();

    const baseUrl = process.env.REACT_APP_TMO_ID_AUTH_URL
    const pattern = "&scope"
    const redirectUrl = baseUrl.replace(pattern, "/status&scope")
    window.location.replace(redirectUrl)
  }

  return (
    <>
      {/* ban indicates the user is logged in */}
      {!user.ban && <LinkButton onClick={login} primary>Login To Redeem Card</LinkButton>}
      {user.ban && <LinkButton href={getVirtualPayRedemptionLink(selectedSubmission.debitCard)} target="_blank" primary>Redeem Card</LinkButton>}
    </>
  )
}

VirtualCardComponent.propTypes = {
  selectedSubmission: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired
}
