import React from "react"

export const GetStartedExlusiveCodeComponent = () => {
  return (
    <section>
      <div className="container pt-5 pb-3 hero3">
        <div className="row no-gutters2 text-center">
          <div className="col">
            <h1 className="magenta exclusive-header font-teleNeoWeb">You scored a special <span style={{"display": "inline-block"}}>T-Mobile</span> discount.</h1>
            <p>Enter your code to access amazing benefits.  Then - call, chat or visit a store to speak to one of our experts to join us!</p>
          </div>
        </div>
      </div>
    </section>
  )
}
