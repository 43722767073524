import React from "react"
import PropTypes from "prop-types"



export const FoundPromotionComponent = ({offerName, customPromoText}) => {

  const renderCustomText = (text) => {
    if (!text) {
      return
    }

    return <div dangerouslySetInnerHTML={{__html: text}}></div>
  }

  return (
  <>
    <h1 className="magenta font-teleNeoWeb">Review your promotion</h1>
    <h3 className="text-left">{offerName}</h3>
    {renderCustomText(customPromoText)}
  </>
  )
}

FoundPromotionComponent.propTypes = {
  offerName: PropTypes.string.isRequired,
  customPromoText: PropTypes.string
}
