import React from "react"
import {Link} from "react-router-dom"
import {Button} from "../components/styled"
import {ROUTE_PATHS} from "../utils/routes"

export const NotFound = () => {
  return (
    <section>
      <div className="container hero3">
        <div className="row no-gutters2">
          <div className="col-md-12 mb-4">
            <h1 className="magenta">Page Not Found</h1>
            <h3>Sorry, that page doesn&apos;t exist. Click below to start a new submission or check the status of an existing submission.</h3>
          </div>
          <div className="ro">
            <div className="col-md-12 mb-4">
              <Link to={ROUTE_PATHS.HOME}><Button style={{cursor: "pointer"}} className="ml-md-3" primary>Home</Button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
