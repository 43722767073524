import styled from "styled-components"

export const TextBoxWrapper = styled.div`
    --max-lines:  ${({maxLines}) => maxLines || '4'};
    max-height: calc(1.5rem * var(--max-lines));
    font-size: small;
    overflow: auto;
    padding-top: 0.2rem;
    text-overflow: ellipsis;
   
`
