import React, {useState, useEffect, useContext} from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import {Formik, Form, Field} from "formik"
import classnames from "classnames"
import {ROUTE_PATHS} from "../../utils/routes"
import {TextInput, SelectInput } from "./index"
import {Button, ErrorMessage} from "../styled"
import {states, addOptionalRegistrationSchema, validZipCodeRegEx} from "../../utils/FormUtils"
import {Link} from "react-router-dom"
import {AppContext} from "../context/AppContext"

export const RegistrationForm = ({onSubmit, fields, isTMobileCustomer, hintOffer}) => {
  const {state} = useContext(AppContext)
  const [imeiVisible, setImeiVisible] = useState(false)
  const [imei2Visible, setImei2Visible] = useState(false)
  const [dealerCodeVisible, setDealerCodeVisible] = useState(false)
  const [lastFourSsnVisible, setLastFourSsnVisible] = useState(false)
  const [phoneVisible, setPhoneVisible] = useState(false)
  const [showSubmitButton, setShowSubmitButton] = useState(true)

  const validAddressRegEx = /^([a-zA-Z0-9!@#$%^&*)(+=._-]+\s+[a-zA-Z0-9]+.*$)/
  const validNameRegex = /^[A-Za-z]+((\s)?(('|-|\.)?([A-Za-z])+))*$/
  const validPhoneLengthRegEx = /(^$)|(^\d{10}$)/
  const notStartInZeroOrOneRegex = /^(?!0|1)\d+$/
  const onlyNumbersRegex = /^[0-9]*$/

  const initialValues = {
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    email1: "",
    email1Confirm: "",
    imei: state.user.imei ? state.user.imei : "",
    imei2: "",
    confirmImei: "",
    confirmImei2: "",
    dealerCode: "",
    lastFourSsn: "",
    phone: ""
  }

  let constantSchema = {
    firstName: Yup
      .string()
      .required("First Name is required.")
      .min(2, "First Name must have at least 2 letters.")
      .matches(validNameRegex, "First Name is invalid."),
    lastName: Yup
      .string()
      .required("Last Name is required.")
      .min(2, "Last Name must have at least 2 letters.")
      .matches(validNameRegex, "Last Name is invalid."),
    address1: Yup
      .string()
      .required("Address is required.")
      .matches(validAddressRegEx, "Address must start with a street number or PO Box."),
    address2: Yup
      .string(),
    city: Yup
      .string()
      .required("City is required.")
      .min(2, "City is invalid.")
      .matches(validNameRegex, "City is invalid."),
    postalCode: Yup
      .string()
      .required("Zip Code is required.")
      .min(5, "Zip Code must be at least five digits.")
      .max(5, "Zip Code must be at least five digits.")
      .matches(validZipCodeRegEx, "Zip Code is invalid."),
    email1: Yup
      .string()
      .email("Email is invalid."),
    email1Confirm: Yup
      .string()
      .email("Email is invalid."),
    phone: Yup
      .string()
      .matches(onlyNumbersRegex, "Please enter a valid phone number consisting of numerics only")
      .matches(validPhoneLengthRegEx, "Phone Number must be 10 digits")
      .matches(notStartInZeroOrOneRegex, "Phone Number cannot start with 0 or 1")
  }

  const validateState = (value) => {
    let error
    if (value === "") {
      error = "State is required."
    }
    return error
  }

  const validatePhone = (value) => {
    let error
    if (value === "") {
      error = "Phone is required."
    }
    return error
  }

  const validateLastFour = (value) => {
    let error
    if (value === "") {
      error = "Last Four of SSN or Tax ID is required."
    }
    return error
  }

  const validateEmail = (value) => {
    let error

    if (hintOffer && value === "") {
      error = "Email is required."
    }
    return error
  }

  const validateConfirmEmail = (value, mail) => {
    let error
    if (value !== mail) {
      error = "Confirm email must match the provided email."
    }
    if (value === "") {
      error = "Confirm Email is required."
    }
    return error
  }

  const validationSchema = addOptionalRegistrationSchema(constantSchema, fields, isTMobileCustomer)
  useEffect(() => {
    if (fields) {
      if (fields.filter(field => field.fieldName === "imei" && field.visible === true).length > 0) {
        setImeiVisible(true)
      }

      if (fields.filter(field => field.fieldName === "imei2" && field.visible === true).length > 0) {
        setImei2Visible(true)
      }

      if (fields.filter(field => field.fieldName === "dealerCode" && field.visible === true).length > 0) {
        setDealerCodeVisible(true)
      }

      if (fields.filter(field => field.fieldName === "lastFourSsn" && field.visible === true).length > 0) {
        setLastFourSsnVisible(true)
      }

      if (fields.filter(field => field.fieldName === "phone" && field.visible === true).length > 0) {
        setPhoneVisible(true)
      }
    }
  }, [fields, isTMobileCustomer, hintOffer])

  useEffect(() => {
    if (state.showSubmitButton) {
      setShowSubmitButton(true)
    } else {
      setShowSubmitButton(false)
    }
  }, [state.showSubmitButton])

  const IMEI_PLACEHOLDER = "15-digit IMEI #"

  const emailSsnColumnClass = classnames({
    "form-group": true,
    "col-md-12": !lastFourSsnVisible,
    "col-md-6": lastFourSsnVisible
  })

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({status, isSubmitting, values}) => (
        <Form>
          <div><p className="small">Fields marked with * are required.</p></div>
          <div className="row no-gutters2">
            <div className="form-group col-md-6">
              <label>First Name*</label>
              <Field name="firstName" type="text" component={TextInput} placeholder=""></Field>
            </div>
            <div className="form-group col-md-6">
              <label>Last Name*</label>
              <Field name="lastName" type="text" component={TextInput} placeholder=""></Field>
            </div>
          </div>
          <div className="row no-gutters2">
            <div className="form-group col-md-8">
              <label>Address*</label>
              <Field name="address1" type="text" component={TextInput} placeholder=""></Field>
            </div>
            <div className="form-group col-md-4">
              <label>Apt./Suite</label>
              <Field name="address2" type="text" component={TextInput} placeholder=""></Field>
            </div>
          </div>
          <div className="row no-gutters2">
            <div className="form-group col-md-4">
              <label>City*</label>
              <Field name="city" type="text" component={TextInput} placeholder=""></Field>
            </div>
            <div className="form-group col-md-4">
              <label>Select State*</label>
              <Field name="state" component={SelectInput} validate={validateState} disabled={false}>
                <option value="">Select State*</option>
                {states.map((state) => {
                  return (<option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>)
                })}
              </Field>
            </div>
            <div className="form-group col-md-4">
              <label>ZIP Code*</label>
              <Field name="postalCode" type="text" component={TextInput} placeholder="" maxLength="5"></Field>
            </div>
          </div>
          <div className="row no-gutters2">
            <div className={emailSsnColumnClass}>
              <label>{`Email${hintOffer ? "*" : ""}`}</label>
              <Field name="email1" type="email" component={TextInput} validate={validateEmail} placeholder=""></Field>
            </div>
            {lastFourSsnVisible &&
            <div className={emailSsnColumnClass}>
              <label>Last 4 of SSN or last 4 business tax ID*</label>
              <Field name="lastFourSsn" type="text" component={TextInput} placeholder="" validate={validateLastFour} maxLength="4"></Field>
            </div>
            }
          </div>
          {phoneVisible && hintOffer &&
            <>
              <div className="row no-gutters2">
                <div className={emailSsnColumnClass}>
                  <label>Confirm Email*</label>
                  <Field name="email1Confirm" type="email" component={TextInput}  validate={value => validateConfirmEmail(value, values.email1)} placeholder=""></Field>
                </div>
              </div>
              <div className="row no-gutters2">
              <div className="form-group col">
                <p>*If you are a Home Internet only customer: This email is where you will receive your Virtual Prepaid Mastercard. </p>
              </div>
            </div>
            </>
          }
          {imeiVisible &&
          <div className="row no-gutters2">
            <div className="form-group col-md-6">
              <label>{imei2Visible ? `${IMEI_PLACEHOLDER} of First Purchased Device*` : `${IMEI_PLACEHOLDER} of Purchased Device*`}</label>
              <Field
                name="imei"
                type="string"
                component={TextInput}
                placeholder=""
              />
            </div>
            <div className="form-group col-md-6">
              <label>{imei2Visible ? "Confirm First IMEI #*" : "Confirm IMEI #*"}</label>
              <Field
                name="confirmImei"
                type="string"
                component={TextInput}
                placeholder=""
              />
            </div>
          </div>}
          {imei2Visible &&
          <div className="row no-gutters2">
            <div className="form-group col-md-6">
              <label>{`${IMEI_PLACEHOLDER} of Second Purchased Device*`}</label>
              <Field
                name="imei2"
                type="string"
                component={TextInput}
                placeholder=""
              />
            </div>
            <div className="form-group col-md-6">
              <label>Confirm Second IMEI #*</label>
              <Field
                name="confirmImei2"
                type="string"
                component={TextInput}
                placeholder=""
              />
            </div>
          </div>}
          {dealerCodeVisible &&
          <div className="row no-gutters2">
            <div className="form-group col-md-12">
              <label>Dealer Code*</label>
              <Field name="dealerCode" type="string" component={TextInput} placeholder=""></Field>
            </div>
          </div>}

          {
            phoneVisible &&
            <>
              <div className="row no-gutters2">
                <div className="form-group col-md-4">
                  <label>Mobile Phone Number*</label>&nbsp;&nbsp;
                  <span className="custom-tooltip">
                    <i className="fa fa-question-circle magenta"></i>
                    <div className="tooltip-text">Are you porting your number into T-Mobile? Enter the number you are planning to port-in.<br></br><br></br> Are you an existing T-Mobile Account? Enter the number you are submitting on for this offer.</div>
                  </span>
                  <Field name="phone" type="text" component={TextInput} validate={validatePhone} placeholder=""></Field>
                </div>
              </div>
              <div className="row no-gutters2">
                <div className="form-group col-md-8">
                </div>
              </div>
            </>
          }

          {
            showSubmitButton &&
            <>
              <div className="form-group hero3 mb-0">
                <Button type="submit" className="btn" id="btnSubmit" primary disabled={isSubmitting}>{isSubmitting ? "Submitting" : "Continue"}</Button>
                {status && status.formError && <ErrorMessage>{status.formError}</ErrorMessage>}
              </div>
            </>
          }

          {
            !showSubmitButton &&
            <>
              <div className="row no-gutters2">
                <div className="form-group col">
                  <p>It looks like you've already completed a submission for this offer, <Link to={ROUTE_PATHS.STATUS}>click here</Link></p>
                </div>
              </div>
            </>
          }
          

        </Form>)}
    </Formik>
  )
}

RegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  isTMobileCustomer: PropTypes.bool.isRequired,
  hintOffer: PropTypes.bool.isRequired,
  showSubmitButton: PropTypes.bool.isRequired
}
