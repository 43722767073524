import React from "react"
import {Formik, Form, Field, ErrorMessage} from "formik"
import {TextInput} from "../forms"
import {Button} from "../styled"
import {addOptionalReCaptchaSchema} from "../../utils/FormUtils"
import PropTypes from "prop-types"
import * as Yup from "yup"

const initialValues = {
    phoneNumber: "",
    recaptcha: ""
}

let constantSchema = {
    phoneNumber: Yup
        .string()
        .required("Phone Number is required")
        .min(10, "Type 10 characters for Phone Number")
}

export const TrackerForm = ({onSubmit}) => {
    const validationSchema = addOptionalReCaptchaSchema(constantSchema)
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {
                ({status}) => (
                    <Form>
                        <div className="row no-gutters2 mb-3">
                            <div className="form-group col-md-12">
                                <Field name="phoneNumber" component={TextInput} type="text" placeholder="Phone Number"></Field>
                            </div>
                        </div>

                        <div className="mt-3">
                            <Button type="submit" className="btn" id="btnSubmit" primary>Check Status</Button>
                            { status && status.formError && <ErrorMessage>{status.formError}</ErrorMessage> }
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
}

TrackerForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}