import React from "react"
import PropTypes from "prop-types"
import {Formik, Form, Field, ErrorMessage} from "formik"
import {TextInput, ReCAPTCHAInput} from "../forms"
import {Button} from "../styled"
import * as Yup from "yup"

import {addOptionalReCaptchaSchema} from "../../utils/FormUtils"

const initialValues = {
  code: "",
  recaptcha: ""
}

let constantSchema = {
  code: Yup
    .string()
    .required("Exclusive Code is required")
    .max(25, "Exclusive Code not to exceed 25 characters")
}
export const LookupForm = ({onSubmit, isReCaptchaVisible}) => {
    const validationSchema = addOptionalReCaptchaSchema(constantSchema, isReCaptchaVisible)
    return(
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({status}) => (
            <Form>
                <div className="row no-gutters2 mb-3">
                    <div className="form-group col-md-12">
                        <Field name="code" component={TextInput} type="text" placeholder="Exclusive Code"></Field>
                    </div>
                </div>
                {isReCaptchaVisible &&
                <div className="row no-gutters2 mb3">
                    <div className="form-group col-md-12">
                    <Field
                      name="recaptcha"
                      component={ReCAPTCHAInput}
                    />
                  </div>
                </div>
          }
          <div className="mt-3">
            <Button type="submit" className="btn" id="btnSubmit" primary>Check Status</Button>
            {status && status.formError && <ErrorMessage>{status.formError}</ErrorMessage>}
          </div>
        </Form>
      )}
    </Formik>
  )
}

LookupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isReCaptchaVisible: PropTypes.bool.isRequired
}
