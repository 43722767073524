import React from "react"
import {PropTypes} from "prop-types"
import {Link} from "react-router-dom"

import {Button} from "./styled"

import {createMarkup} from "../utils/Helpers"
import {ROUTE_PATHS} from "../utils/routes"
import {TextBoxWrapper} from "../components/styled/index"

export const TermsAndConditionsComponent = ({terms, hasMailIn}) => {
  const nextPage = hasMailIn ? ROUTE_PATHS.SUBMISSION_METHOD : ROUTE_PATHS.REGISTER
  return (
    <>
      <p className=" mt-3 mb-1"><strong>Promotion Terms</strong></p> 
      <TextBoxWrapper maxLines={4}  className="mb-4">
      <p  className="mb-0" dangerouslySetInnerHTML={createMarkup(terms)}></p>
      </TextBoxWrapper>
      <Link to={nextPage} ><Button style={{cursor: "pointer"}} className="btn agree-to-terms" primary>Continue</Button></Link>
      <p className="small">By proceeding, I verify I have read and agree to the Terms & Conditions of this promotion.</p>
    </>
  )
}

TermsAndConditionsComponent.propTypes = {
  terms: PropTypes.string.isRequired,
  hasMailIn: PropTypes.string
}
