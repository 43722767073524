import BaseApi from "./BaseApi"

const EMAIL_ENDPOINT = "/email"

class EmailApi extends BaseApi {
  async sendContactEmail(contactValues) {
    try {
      return await this.post(`${EMAIL_ENDPOINT}/contact`, contactValues )
    } catch (error) {
      throw new Error("Unable to send contact details")
    }
  }

  async sendCodeResendEmail(resendValues) {
    try {
      const response = await this.post(`${EMAIL_ENDPOINT}/code-resend`, resendValues)
      return response.data
    } catch (error) {
      const {data} = error.response
      throw new Error(data)
    }
  }
}

export default new EmailApi()
