import React from "react"
import PropTypes from "prop-types"

import {ErrorMessage } from "../styled"

export const TextInput = ({
  field,
  form: {touched, errors},
  disabled,
  secondary,
  ...props
}) => {
  return (
    <>
      <input type={props.type} {...field} {...props} className="form-control" placeholder={props.placeholder} disabled={disabled} />
      {touched[field.name] && errors[field.name] && <ErrorMessage secondary={secondary}>{errors[field.name]}</ErrorMessage>}
    </>
  )
}

TextInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool
}
