import React from "react"
import PropTypes from "prop-types"

import {ErrorMessage } from "../styled"

export const SelectInput = ({
  field,
  form: {touched, errors, handleChange},
  ...props
}) => {
  return (
    <>
      <select {...field} {...props} className="form-control" onChange={handleChange} disabled={props.disabled}>
        {props.children}
      </select>
      {touched[field.name] && errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </>
  )
}

SelectInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired
}
