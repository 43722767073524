import React, {useContext, useState, useEffect} from "react"
import PropTypes from "prop-types"

import {AppContext} from "../components/context/AppContext"
import {UploadForm} from "../components/forms"

import {SubmissionApi} from "../utils/api"
import {ROUTE_PATHS} from "../utils/routes"
import {getCampaignAsset} from "../utils/Helpers"
import {ASSET_KEYWORDS} from "../utils/assetKeywords"
import AnalyticsService from "../utils/AnalyticsService"

export const Upload = (props) => {
  const {state, dispatch} = useContext(AppContext)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const {campaignAssets} = state.campaign
  const uploadItem = getCampaignAsset(campaignAssets, ASSET_KEYWORDS.UPLOAD_ITEM)

  useEffect(() => {
    
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.UPLOAD);
     
    if (state.iSubmission.statusId !== 4) {
      props.history.push(ROUTE_PATHS.HOME)
    }
  })

  const upload = async () => {
    try {
      return await SubmissionApi.upload(state.files, state.iSubmission.iSubmitId,)
    } catch (error) {
      throw error
    }
  }

  const finalize = async () => {
    try {
      const submission = {...state.iSubmission, offerId: state.campaign.offerId}

      return await SubmissionApi.finalize(submission)
    } catch (error) {
      throw error
    }
  }

  const onSubmit = async () => {
    try {

      state.user.primaryPhoneNumber = state.iSubmission.telephone1
      state.iSubmission.isTFBAccount = state.user.isTFBAccount

      setSubmitting(true)

      setErrorMessage("")

      if (state.files.length === 0) {
        setErrorMessage("You must upload at least one file")
        setSubmitting(false)
        return
      }

      await upload()


      AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"Upload",action:"Upload",label:"Upload_Site"})

      const result = await finalize()

      dispatch({type: "SETISUBMISSION", payload: result})

      setSubmitting(false)

      AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"Upload",action:"Submit",label:"Upload Site"})
      props.history.push(ROUTE_PATHS.THANKS)

    } catch (error) {
      setErrorMessage("Something went wrong please try again")
      setSubmitting(false)
    }
  }

  return (
    <section>
      <div className="container hero3">
        <div className="row no-gutters2">
          <div className="col-md-12">
            <h1 className="magenta">Upload some images and you&apos;re done.</h1>
            {uploadItem && <h2 className="magenta">{`Submit ${uploadItem}`}</h2>}
            <UploadForm instructions={getCampaignAsset(campaignAssets, ASSET_KEYWORDS.UPLOAD_INSTRUCTIONS)} handleSubmit={onSubmit} isSubmitting={submitting} errorMessage={errorMessage} />
          </div>
        </div>
      </div>
    </section>
  )
}

Upload.propTypes = {
  history: PropTypes.object
}

Upload.requiresAuth = true
