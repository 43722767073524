import React, {useEffect, useState, useContext} from "react"

import {OfferApi} from "../utils/api"
import {parseClientFaq} from "../utils/Helpers"

import {AppContext} from "../components/context/AppContext"
import { FaqContainerComponent} from "../components"
import { ROUTE_PATHS } from "../utils/routes"
import AnalyticsService from "../utils/AnalyticsService"

export const Faq = () => {
  const {dispatch, state} = useContext(AppContext)
  const [isFetchingFaq, setIsFetchingFaq ] = useState(false)

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.FAQ);
     
    const fetchFaq = async () => {
      if (state.faq.length > 0) {
        return
      }

      setIsFetchingFaq(true)

      try {
        const faq = await OfferApi.getClientFaqs()
        const parsedFaq = await parseClientFaq(faq.data)

        dispatch({type: "SETFAQ", payload: parsedFaq})
        setIsFetchingFaq(false)

      } catch (error) {
        setIsFetchingFaq(false)
      }
    }

    fetchFaq()

  }, [dispatch, state.faq ])
  return (
    <section>
      <div className="container hero3">
        <div className="row no-gutters2">
          <div className="col-md-12 mb-4">
            <h1 className="magenta faqHead">FAQs</h1>
            {isFetchingFaq && <div>Loading ...</div>}
            {state.faq.map((question) => <FaqContainerComponent key={question.header} questionGroup={question}>{question.header}</FaqContainerComponent>)}
          </div>
        </div>
      </div>
    </section>
  )
}
