import React, { useReducer, createContext, useEffect} from "react"
import PropTypes from "prop-types"

export const STATE_NAME = "TMO_PROMO_STATE"

const initialState = {
  user: {phoneNumbers: []},
  selectedPhone: "",
  campaign: {},
  iSubmission: null,
  files: [],
  submissions: [],
  selectedSubmission: null,
  faq: [],
  choseMailIn: false,
  lookupValues: {}
}

const persistedState = JSON.parse(sessionStorage.getItem(STATE_NAME))

const finalInitialState = {...initialState, ...persistedState}

const appReducer = (state, action) => {
  switch (action.type) {
  case "SETISUBMISSION":
    return {...state, iSubmission: action.payload }
  case "SETFILES":
    return {...state, files: action.payload}
  case "SETUSER" :
    return {...state, user: action.payload}
  case "SETSUBMISSIONS":
    return {...state, submissions: action.payload}
  case "SETCAMPAIGN":
    return {...state, campaign: action.payload}
  case "SETSELECTEDPHONE":
    return {...state, selectedPhone: action.payload}
  case "SETSELECTEDSUBMISSION":
    return {...state, selectedSubmission: action.payload }
  case "SETFAQ":
    return {...state, faq: action.payload}
  case "SETCHOSEMAILIN":
    return {...state, choseMailIn: action.payload}
  case "SETLOOKUPVALUES":
    return {...state, lookupValues: action.payload}
  case "RESETSTATE":
    return {...state, ...initialState, user: action.payload}
  default:
    throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const AppContext = createContext()

export const AppProvider = props => {
  const [state, dispatch] = useReducer(appReducer, finalInitialState)

  useEffect(() => {
    sessionStorage.setItem(STATE_NAME, JSON.stringify(state))
  }, [state])

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {props.children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.element
}
