import React from "react"
import {Link} from "react-router-dom"
import { ROUTE_PATHS } from "../utils/routes"
import { Button, LinkButton } from "./styled"
import AnalyticsService from "../utils/AnalyticsService"
import PropTypes from "prop-types"

export const GetStartedComponent = ({isBroadband}) => {

  const sendEventAnalytic = (analyticText) => {
    
    AnalyticsService.sendGa4Event({event: 'hookup_lookup' , category: "Landing", action: analyticText,  label: "continue"})
    if('not_current_tmo' === analyticText){
      window.location.assign(ROUTE_PATHS.EXCLUSIVECODE)
    } 
   
  }

  return (
    <section>
      <div className="container pt-5 pb-3 hero3">
        <div className="row no-gutters2 text-center">
          <div className="col">
            <h1 className="magenta exclusive-header font-teleNeoWeb">You scored a special <span style={{"display": "inline-block"}}>T-Mobile</span> discount.</h1>
            {isBroadband && <>
              <br></br>
              <p className="text-center">Enter your code to access amazing benefits.</p>
              <p className="text-center" >Please find your assigned billing phone number on your bill under <Link to={ROUTE_PATHS.FAQ}>"connected devices"</Link>.</p><br></br></>}
            {!isBroadband && <>
              <p className="text-left mb-3" >Enter your code to access amazing benefits. Then - call, chat or visit a store to speak to one of our experts to join us!</p>
              <p className="text-left mb-3" >If you are a Home Internet only customer, please find your assigned billing phone number on your bill under  <Link to={ROUTE_PATHS.FAQ}>"connected devices"</Link>.</p></>}
          </div>
        </div>

        {isBroadband && <div className="col-md-12 text-center">
            <LinkButton className="btn" id="btnSubmit"
                href={process.env.REACT_APP_TMO_ID_AUTH_URL} 
                onClick={() => sendEventAnalytic('current_tmo_customer')}
                primary>Click here to enter promo code</LinkButton>
        </div>}

        {!isBroadband && <div className="row no-gutters2 text-center">
          <div className="col-md-6">
            <Button onClick={() =>  sendEventAnalytic('not_current_tmo') } className="btn-primary1" style={{"marginBottom": "15px"}}>
              I am not a current T-Mobile Customer
            </Button>
          </div>
          <div className="col-md-6">
            <div className="dropdown">
              <Button className="btn-primary1 dropdown-toggle"  type="button" data-toggle="dropdown" aria-expanded="false" style={{"marginBottom": "15px"}}>
              I am a current T-Mobile Customer
              </Button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href={process.env.REACT_APP_TMO_TFB_ID_AUTH_URL} onClick={() => sendEventAnalytic('tmo_for_BC')} >I am T-Mobile Business Customer</a>
                <a className="dropdown-item" href={process.env.REACT_APP_TMO_ID_AUTH_URL} onClick={() => sendEventAnalytic('current_tmo_customer') } >I am T-Mobile Customer</a>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </section>
  )
}

GetStartedComponent.propTypes = {
  isBroadband: PropTypes.bool
}