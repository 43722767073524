import React, {useEffect} from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"
import {NoWrapSpan} from "../components/styled"

export const Contact = () => {
  useEffect(()=>{
   AnalyticsService.sendUniquePageview(ROUTE_PATHS.CONTACT);
  })
  return (
    <section>
      <div className="container hero3">
        <div className="row no-gutters2">
          <div className="col-md-12 mb-4">
            <h1 className="magenta">Contact us.</h1>
            <p>Find answers to Frequently Asked Questions <Link to={ROUTE_PATHS.FAQ}>here</Link>.</p>
            <p>Or Dial 611 from your <NoWrapSpan>T-Mobile</NoWrapSpan> phone or call <a href="tel:18009378997"><NoWrapSpan>1-800-937-8997</NoWrapSpan></a></p>
          </div>
        </div>
      </div>
    </section>
  )
}

Contact.propTypes = {
  history: PropTypes.object
}

