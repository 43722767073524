import React, {useContext, useEffect} from "react"
import {AppContext} from "../components/context/AppContext"

import {FoundPromotionComponent, TermsAndConditionsComponent, QuestionsAndContactComponent} from "../components"

import {getCampaignAsset} from "../utils/Helpers"
import {ASSET_KEYWORDS} from "../utils/assetKeywords"
import {ROUTE_PATHS} from "../utils/routes"
import AnalyticsService from "../utils/AnalyticsService"

export const Promotion = () => {

  const {state} = useContext(AppContext)
  const {campaign} = state
  useEffect(() => {  
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.PROMOTION);
     
  })

  return (
    <section>
      <div className="container hero3">
        <div className="row no-gutters2">
          <div className="col-md-12 text-left">
            <FoundPromotionComponent offerName={campaign.name} customPromoText={getCampaignAsset(campaign.campaignAssets, ASSET_KEYWORDS.CUSTOM_PROMO_TEXT)} />
            <TermsAndConditionsComponent terms={campaign.termsAndConditions} hasMailIn={campaign.offerFormUrl} />
            <QuestionsAndContactComponent showHeaderRow />
          </div>
        </div>
      </div>
    </section>
  )
}


Promotion.requiresAuth = false