import React, { useState, useEffect, useContext } from "react"
import {AuthApi, SubmissionApi } from "../utils/api"
import {ROUTE_PATHS} from "../utils/routes"
import {AppContext} from "../components/context/AppContext"
import {TrackerRowComponent} from "../components"
import AnalyticsService from "../utils/AnalyticsService"
import { setDnsSetting } from "../utils/Helpers"
import {TrackerForm} from "../components/forms"
import { ErrorMessage } from "../components/styled"

const submissionValues = {
  phoneNumber: "",
  recaptcha: "",
  isTFBAccount: false,
  userId: "",
  b2bOrg: ""
}

export const Tracker = () => {
  const {state} = useContext(AppContext)
  const { dispatch } = useContext(AppContext)
  const [isLoggingIn, setIsLoggingIn] = useState(false)  
  const [errorMessage, setErrorMessage] = useState() 

  const setDnsCookie = (value) => {
    const date = new Date()
    const expiration = new Date(date.getTime() + 31536000000)
    const cookie = (value.toLowerCase() === "donotsell" ? "dnt=1" : "dnt=0") + ";domain=" + window.location.domain + ";expires=" + expiration.toGMTString()
    document.cookie = cookie
    }

    const cleanAndOrderSubmissions = (unorderedSubmissions) => {
      const mergedSubmissions = [...unorderedSubmissions.submissions, ...unorderedSubmissions.iSubmissions]
      const distinctSubmissions = []
      const map = new Map()
  
      //get an array of distinct submissions by tracking id
      for (const submission of mergedSubmissions) {
        if (!map.has(submission.trackingId)) {
          map.set(submission.trackingId, true)
          distinctSubmissions.push(submission)
        }
      }
  
      return distinctSubmissions.sort((sub1, sub2) => {
        if (sub1.stampDate > sub2.stampDate) {
          return -1
        }
  
        if (sub1.stampDate < sub2.stampDate) {
          return 1
        }
  
        return 0
      })
    }

    const resetState = () => {
      setErrorMessage("")
    }

    useEffect(() => {
      const login = async () => {
        setIsLoggingIn(true)
        let isExistingCustomer = false
        let response 
        try {

          if (sessionStorage.getItem("pvtkey") === null) {
            var rs = require("jsrsasign");
            var keypair = rs.KEYUTIL.generateKeypair("RSA", 1024);
            sessionStorage.setItem("pubkey", rs.KEYUTIL.getPEM(keypair.pubKeyObj));
            sessionStorage.setItem(
              "pvtkey",
              rs.KEYUTIL.getPEM(keypair.prvKeyObj, "PKCS1PRV")
            );
          } 
           
          const searchParams = new URLSearchParams(window.location.search)
          const code = searchParams.get("code")
          const sessionNumber = searchParams.get("session_num");
          const pubkey = sessionStorage.getItem("pubkey");
          const stateValue = searchParams.get("state");
          const isFromStatusPage = true
          
          if (!code || state.lookupValues.isLoggedIn) {
            setIsLoggingIn(false)      
            isExistingCustomer = false    
            return
          }
          else if (state.user.userToken) {
              setIsLoggingIn(false)
              return
            }
          else {
            isExistingCustomer = true
          }
  
          if (isExistingCustomer) {
            response = await AuthApi.loginWithTmoId(code, state.lookupValues.phoneNumber, sessionNumber,pubkey, stateValue, isFromStatusPage)
            
            sessionStorage.setItem("primaryPhoneNumber", response.primaryPhoneNumber)          
            const dnsSetting = await AuthApi.getGlobalDnsSettingV2(response.tMobileId)
            setDnsCookie(dnsSetting.localDoNotSellSetting)
            setDnsSetting(dnsSetting, response.tMobileId)

            dispatch({ type: "SETUSER", payload: response })

            if (!response.isTFBAccount) {

              let submissionsResponse

              submissionValues.phoneNumber = response.primaryPhoneNumber
              submissionValues.isTFBAccount = false

              submissionsResponse = await SubmissionApi.orderStatusLookup(submissionValues)

              const submissions = cleanAndOrderSubmissions(submissionsResponse)
              dispatch({type: "SETSUBMISSIONS", payload: submissions})

              if (submissions.length < 1) {
                setErrorMessage("No submissions were found with the information provided.")
              }
            }
          }
          setIsLoggingIn(false)
        } catch (error) {        
          if (error.message === "Invalid Role" ) {
            setErrorMessage("We're sorry, you must be a primary account holder to apply this promotion. For more information, please contact T-Mobile Customer Care at 1.800.937.8997 for assistance.")          
          }
          else {
            setErrorMessage("We were unable to log you in using your T-Mobile ID. Please login again.")
          }
          setIsLoggingIn(false)
        }
      }
      AnalyticsService.sendUniquePageview(ROUTE_PATHS.TRACKER);
      login()

    }, [dispatch, state.lookupValues.isLoggedIn, state.lookupValues.phoneNumber, state.user.userToken])
  
  const sectionStyles = {
    minHeight: "30rem"
  }

  const submitPhoneNumber = async (values) => {
    resetState()

    try {
      
      submissionValues.phoneNumber = values.phoneNumber
      submissionValues.isTFBAccount = true
      submissionValues.userId = state.user.userId
      submissionValues.b2bOrg = state.user.b2bOrg

      let submissionsResponse

      submissionsResponse = await SubmissionApi.orderStatusLookup(submissionValues)

      const submissions = cleanAndOrderSubmissions(submissionsResponse)

      dispatch({type: "SETSUBMISSIONS", payload: submissions})

      if (submissions.length < 1) {
        setErrorMessage("No submissions were found with the information provided.")
      }
    }
    catch (error) {
      setErrorMessage("No submissions were found with the information provided -.")
    }
  }

  return (
    <>
    {
      (state.user.isTFBAccount && !isLoggingIn) &&
      <section>
        <div className="container hero3">
          <div className="row no-gutters2">
            <div className="col">
              <h1 className="magenta">Check the status of your submissions</h1>
              <p>Please enter the phone number on your account to check the status of your submission.</p>
            </div>
          </div>
          <div className="container" style={{"paddingLeft": "0px"}}>
            <div className="row no-gutters2">
              <div className="mx-auto col-md-12">
                <TrackerForm onSubmit={submitPhoneNumber} />
              </div>
            </div>
          </div>
        </div>
        <p>&nbsp;</p>
      </section>
    }

    { 
      state.submissions &&
        <section style={sectionStyles}>
          <div className="container hero3">
            <div className="row no-gutters2">
              <div className="col">
                <h1 className="magenta">Reward Tracker</h1>
              </div>
              <div className="col-md-12">
                <h3>Check the status of your reward</h3>
                <div id="tracker">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Offer Description</th>
                        <th scope="col">Offer Value</th>
                        <th scope="col">Submission Date</th>
                      </tr>
                    </thead>
                    <tbody>
                    {state.submissions.map((submission) => <TrackerRowComponent submission={submission} key={submission.trackingId}/>)}            
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="pt-3 text-center text-md-left">
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </div>
          </div>
        </section>
      }

      {
        <section>
          <div className="container hero3">
            <div className="row no-gutters2">
              <div className="col" style={{"paddingLeft": "0px"}}>
                
              </div>              
            </div>
          </div>
        </section>
      }
    </>
  )
}

