import React from "react"
import {Formik, Form, Field} from "formik"
import {Modal, ModalBody} from 'reactstrap'
import PropTypes from 'prop-types'
import * as Yup from "yup"

import {TextInput} from "../forms"
import {Button, ErrorMessage} from "../styled"
import AnalyticsService from "../../utils/AnalyticsService"

const initialValues = {
  email: ""
}
const onClickReSendCodeAnalytic = () => {
  AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"modal",action:"resend_code",label:"email",})
}

let validationSchema = Yup.object().shape({
  email: Yup
    .string()
    .required("Email is required.")
    .email("Email is invalid.")
})

export const ResendCodeForm = ({isModalVisible, toggleModal, onSubmit, error}) => {
  return (
    <Modal isOpen={isModalVisible} centered>
      <ModalBody style={{border: "none"}}>
        <div className="container">
          <div className="row no-gutters2">
            <div className="col resendHead">
              <h1 className="magenta">Resend Exclusive Code</h1>
              <p>Enter your T-Mobile US employee email address and if you have active codes we will resend them to you.</p>
            </div>
          </div>
          <div className="container">
            <div className="row no-gutters2">
              <div className="mx-auto col-md-12">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({isSubmitting}) => (
                    <Form>
                      <div className="row no-gutters2 mb-3">
                        <div className="form-group col-md-12">
                          <Field name="email" component={TextInput} type="text" placeholder="Email"></Field>
                        </div>
                      </div>
                      <div className="mt-3 resendBody">
                        <Button type="submit" className="btn"  id="btnSubmit" disabled={isSubmitting} primary onClick={onClickReSendCodeAnalytic}>
                          {isSubmitting ? "Sending" : "Resend Code"}
                        </Button>
                        <Button className="btn ml-3" onClick={toggleModal}>Cancel</Button>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

ResendCodeForm.propTypes = {
  isModalVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
  onSubmit: PropTypes.func,
  error: PropTypes.string
}
