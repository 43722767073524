import React, {useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"

import {ROUTE_PATHS} from "../utils/routes"
import { OfferApi, SubmissionApi } from "../utils/api"
import {handleSubmissionError, formatPhoneNumber} from "../utils/FormUtils"
import {isEmpty} from "../utils/Helpers"
import AnalyticsService from "../utils/AnalyticsService"
import {AppContext} from "../components/context/AppContext"
import { WelcomeComponent, GetStartedComponent, StatusLookupComponent, QuestionsAndContactComponent} from "../components"
import { LegalText,  ErrorMessage} from "../components/styled"
import {EXTERNAL_ROUTE_PATHS} from "../utils/externalRoutes"

export const Home = ({location, history, match}) => {
  const {dispatch} = useContext(AppContext)
  const [isRecaptchaVisible, setIsRecaptchaVisible] = useState(false)
  const [isLoadingCaptcha, setIsLoadingCaptcha] = useState(false)
  const isLoggingIn = useState(false)
  const [isNetworkOutage, setIsNetworkOutage] = useState(false)
  const errorMessage = useState()
  const [errorMessageLookup, setErrorMessageLookup] = useState()
  // eslint-disable-next-line
  const [hasPendingSubmission, setHasPendingSubmission] = useState(false)
  // eslint-disable-next-line
  const [hasRegisteredSubmission, setHasRegisteredSubmission] = useState(false)
  // eslint-disable-next-line
  const [hasCompletedSubmission, setHasCompletedSubmission] = useState(false)
  // eslint-disable-next-line
  const CODE_IS_MAX_USES_MESSAGE = "This code has already been used. If you need help, please reach out to the person you received the code from or contact our Team of Experts at 1.800.937.8997."
  // eslint-disable-next-line
  const OFFER_IS_EXPIRED_MESSAGE = "This offer is no longer available. For more information, please contact T-Mobile Customer Care at 1.800.937.8997 for assistance."
  // eslint-disable-next-line
  const exclusiveCode = match.params.exclusiveCode || ""
  // eslint-disable-next-line
  const STATUS_ID = {
    PENDING: 1,
    REGISTERED: 2,
  }

  // eslint-disable-next-line
  const offerIsExpired = (offer) => {
    const today = Date.now()
    if (today < Date.parse(offer.goLiveDate) || Date.parse(offer.expirationDate) < today) {
      return true
    }
  }
    // eslint-disable-next-line
  const codeIsAtMaxUses = (offer) => {
    if (offer.hookupCode.currentUses + offer.hookupCode.redeemedUses >= offer.hookupCode.maximumUsage) {
      return true
    }
  }

  const cleanAndOrderSubmissions = (unorderedSubmissions) => {
    const mergedSubmissions = [...unorderedSubmissions.submissions, ...unorderedSubmissions.iSubmissions]
    const distinctSubmissions = []
    const map = new Map()

    //get an array of distinct submissions by tracking id
    for (const submission of mergedSubmissions) {
      if (!map.has(submission.trackingId)) {
        map.set(submission.trackingId, true)
        distinctSubmissions.push(submission)
      }
    }

    return distinctSubmissions.sort((sub1, sub2) => {
      if (sub1.stampDate > sub2.stampDate) {
        return -1
      }

      if (sub1.stampDate < sub2.stampDate) {
        return 1
      }

      return 0
    })
  }

  useEffect(() => {
    const getRecaptchaVisible = async () => {
      setIsLoadingCaptcha(true)
      try {
        const maintenance = await OfferApi.getAssetsByKeywordHookup("MaintenanceFlag")

        if (maintenance.notes.toLowerCase() === "true") {
          setIsNetworkOutage(true)
          return
        }

        const result = await OfferApi.getClientReCaptchaVisible()
        setIsRecaptchaVisible(result)
        setIsLoadingCaptcha(false)

      } catch (error) {
        setIsNetworkOutage(true)
        setIsLoadingCaptcha(false)
      }
    }
    getRecaptchaVisible()   
  }, [])

  const onStatusLookupSubmit = async (values, {setSubmitting, setStatus}) => {
    values.phoneNumber = formatPhoneNumber(values.phoneNumber)
    let lookupValues = {...values}
    dispatch({type: "SETLOOKUPVALUES", payload: lookupValues})
    setErrorMessageLookup("")
    try {
      values.phoneNumber = formatPhoneNumber(values.phoneNumber)
      let response
      
      response = await SubmissionApi.orderStatusLookup(values)

      if (isEmpty(response.submissions) && isEmpty(response.iSubmissions)) {
        setErrorMessageLookup("There are no submissions based on your search criteria")
        setSubmitting(false)
      } else {
        const submissions = cleanAndOrderSubmissions(response)
        dispatch({type: "SETSUBMISSIONS", payload: submissions})
        AnalyticsService.sendGa4Event( {event: "hookup_lookup",category:"Home",action:"OSL",label:"Upload Site"})   
        history.push(ROUTE_PATHS.TRACKER)
      }
    } catch (error) {
      handleSubmissionError(setSubmitting, setStatus)
    }
  }

  return (
    <>
      <WelcomeComponent />
      {isNetworkOutage
        ?
        <div className="text-center container">
          <h1 className="magenta">Temporarily Unavailable</h1>
          <p>This site is currently under going scheduled maintenance. We&apos;ll be back soon.</p>
          <p>Thank you for your patience.</p>
        </div>
        :
        <>
        {!isLoadingCaptcha && <GetStartedComponent isBroadband={false}/>}
          {!isLoggingIn && errorMessage && <div className="container">
            <div className="row no-gutters2">
              <div className="mx-auto col-md-12">
                <ErrorMessage className="text-center">{errorMessage}</ErrorMessage>
              </div>
            </div>
          </div>}
          <>
            <div className="container">
              <div className="row no-gutters2">
              <div className="mx-auto col-md-12">
              <QuestionsAndContactComponent isHomePage={true}/>
                </div>
                <div className="mx-auto col-md-12">
                  <LegalText>
                  Capable device required; coverage not available in some areas. Some uses may require certain plan or feature; see plan for details. While 5G access won&apos;t require a certain plan or feature, some uses/services might. See <a href={EXTERNAL_ROUTE_PATHS.COVERAGE_MAP}>Coverage details</a>, <a href={EXTERNAL_ROUTE_PATHS.TMO_LEGAL}>Terms and Conditions</a>, and <a href={EXTERNAL_ROUTE_PATHS.INTERNET_POLICY}>Open Internet</a> information for network management details (like video optimization).
                  </LegalText>
                </div>
              </div>
              <p className="hookupLookup">To check code usage or re-send a code e-mail (if applicable), <Link to={ROUTE_PATHS.LOOKUP}>click here.</Link></p>
            </div>
            <StatusLookupComponent
              onSubmit={onStatusLookupSubmit}
              isReCaptchaVisible={isRecaptchaVisible}
              error={errorMessageLookup}
            />
          </>
        </>
      }
    </>
  )
}

Home.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
