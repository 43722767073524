import styled from "styled-components"

export const ErrorMessage = styled.div`
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: ${props => {
    return props.secondary ? "#fff;" : "#dc3545;"
  }}
`
